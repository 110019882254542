// Import necessary packages and components
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Flex,
} from '@chakra-ui/react';
import theme from '../theme';

const ContactUs = () => {
  return (
    <Box marginX="180px" mt="68px">
      <VStack spacing={8} align="stretch">
        <Heading
          fontFamily={theme.fonts.family.medium}
          fontWeight="500"
          fontSize={`clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`}
          mb={5}
          textAlign="right"
          bg="#3080E233"
          p="40px"
          borderRadius="16px"
        >
          روش‌های ارتباط با ما
        </Heading>
        <Flex mb="68px">
          <Box
            ml="110px"
            textAlign="right"
            justifyContent="space-between"
            height="100%"
          >
            <Box mb="70px">
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="600"
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                mb="6px"
              >
                آدرس:
              </Text>
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              >
                بزرگراه سرلشکر بابایی، بلوار نیروی زمینی ارتش، مجتمع الماس، مرکز
                نوآوری فردا، طبقه اول
              </Text>
            </Box>
            <Box alignSelf="flex-end">
              <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color="#0C0B0B"
              >
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="600"
                  fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                >
                  ارتباط سازمانی:
                </Text>
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                >
                  info@fardabank.com
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color="#0C0B0B"
              >
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="600"
                  fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                >
                  پشتیبانی:
                </Text>
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                >
                  support@fardabank.com
                </Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color="#0C0B0B"
              >
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="600"
                  fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                >
                  شماره تماس:
                </Text>
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                >
                  02112345678
                </Text>
              </Flex>
            </Box>
          </Box>
          <Box textAlign="center" width="100%">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.836753056975!2d51.34760161519899!3d35.76826188016856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e015d2c7ab1f9%3A0xfb9e0f6615468613!2sTehran!5e0!3m2!1sen!2sir!4v1638812418283!5m2!1sen!2sir"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen={false}
              loading="lazy"
              title="location"
            ></iframe>
          </Box>
        </Flex>
      </VStack>
    </Box>
  );
};

export default ContactUs;
