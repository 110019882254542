import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from 'src/mobile/Home';
import Report from 'src/mobile/Report';
import LastReports from 'src/mobile/LastReports';
import GoogleAuthCallback from 'src/mobile/GoogleAuthCallback';
import AboutUs from 'src/mobile/AboutUs';
import ContactUs from 'src/mobile/ContactUs';
import LoginError from 'src/mobile/LoginError';
import PaymentResult from 'src/mobile/PaymentResult';
import MoreDetails from 'src/mobile/MoreDetails';
import { PrivateRoute } from 'src/mobile/PrivateRoutes';
import BottomNavigation from 'src/components/mobile/BottomNavigation';
import Splash from 'src/mobile/Splash';
import Faq from 'src/mobile/faq';
import { AuthContext } from 'src/context/AuthContext';
import { Box, Spinner } from '@chakra-ui/react';
import Login from 'src/mobile/Login';
import NewRequest from 'src/mobile/NewRequest';
import Profile from 'src/mobile/Profile';
import Plans from 'src/mobile/Plans';
import Payment from 'src/mobile/Payment';
import UserPlans from 'src/mobile/UserPlans';
import UserTransactions from 'src/mobile/UserTransactions';
import BuyPlans from 'src/mobile/BuyPlans';

const MobileLayout: React.FC = () => {
  const location = useLocation();
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const authContext = useContext(AuthContext);

  const noNavRoutes = [
    '/welcome',
    '/404',
    '/login',
    '/new-request',
    '/plans',
    '/payment',
    '/profile/transactions',
    'aboutus',
    '/purchase-plans',
  ];
  const shouldShowNav = !noNavRoutes.some((route) =>
    location.pathname.match(route)
  );

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
    if (hasVisitedBefore) {
      setIsFirstVisit(false);
    } else {
      localStorage.setItem('hasVisitedBefore', 'true');
    }
    setInitialLoading(false);
  }, []);

  if (!authContext) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  const { isAuthenticated, isLoading } = authContext;

  if (isLoading || initialLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (isFirstVisit) {
    return (
      <Routes>
        <Route path="/welcome" element={<Splash />} />
        <Route path="*" element={<Navigate to="/welcome" />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <LastReports /> : <Navigate to="/login" />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/login/google" element={<GoogleAuthCallback />} />
        <Route path="/login/error" element={<LoginError />} />
        <Route
          path="/new-request"
          element={
            <PrivateRoute>
              <NewRequest />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/plans"
          element={
            <PrivateRoute>
              <UserPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/transactions"
          element={
            <PrivateRoute>
              <UserTransactions />
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateRoute>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchase-plans"
          element={
            <PrivateRoute>
              <BuyPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/:planID"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/:reportId"
          element={
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <Faq />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-result"
          element={
            <PrivateRoute>
              <PaymentResult />
            </PrivateRoute>
          }
        />

        <Route
          path="/aboutus"
          element={
            <PrivateRoute>
              <AboutUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/moredetails"
          element={
            <PrivateRoute>
              <MoreDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/contactus"
          element={
            <PrivateRoute>
              <ContactUs />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {shouldShowNav && <BottomNavigation />}
    </>
  );
};

export default MobileLayout;
