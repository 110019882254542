import React, { useState, useEffect, useRef } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  Button,
  Box,
  Input,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider,
  Flex,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import theme from '../../theme';
import ArrowDownChevron from '../../assets/icons/ArrowDown.png';
import { ReactComponent as CloseModal } from 'src/assets/icons/CloseModal.svg';
import { ReactComponent as ClearTextIcon } from 'src/assets/icons/ClearTextIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';
import CustomRadiobox from '../CustomRadiobox';

interface Option {
  value: string;
  label: string;
}

interface DropdownCountryCheckboxesProps {
  fetchOptions: () => Promise<Option[]>;
  placeholder: string;
  onConfirm: (selectedValue: string) => void;
}

const DropdownCountryCheckboxes: React.FC<DropdownCountryCheckboxesProps> = ({
  fetchOptions,
  placeholder,
  onConfirm,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMenuOpen && options.length === 0) {
      fetchOptions()
        .then((data) => {
          setOptions(data);
          setFilteredOptions(data.slice(0, 6)); // Show the first 6 data items initially
        })
        .catch((error) => console.error('Error loading options:', error));
    }
  }, [isMenuOpen, fetchOptions]);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const startMatches = options.filter((option) =>
        option.label.toLowerCase().startsWith(lowerCaseQuery)
      );
      const containsMatches = options.filter(
        (option) =>
          !option.label.toLowerCase().startsWith(lowerCaseQuery) &&
          option.label.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredOptions([...startMatches, ...containsMatches].slice(0, 6)); // Show only the first 6 results
    } else {
      setFilteredOptions(options.slice(0, 6));
    }
  }, [searchQuery, options]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchInputRef.current && !isFocused) {
      searchInputRef.current.focus();
    }
  }, [isFocused]);

  const handleCheckboxChange = (value: string) => {
    setSelectedValue(value === selectedValue ? '' : value);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onConfirm(selectedValue);
    setIsMenuOpen(false);
    searchInputRef.current?.blur();
    onClose();
  };
  const clearInput = () => {
    setSearchQuery('');
    searchInputRef.current?.focus();
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    onOpen();
    searchInputRef.current?.focus();
  };

  const handleModalClose = () => {
    setIsMenuOpen(false);
    searchInputRef.current?.blur();
    onClose();
  };

  const handleItemClick = () => {
    searchInputRef.current?.focus();
  };

  return (
    <Box marginBottom="20px">
      <Menu isOpen={isMenuOpen} onOpen={handleMenuOpen}>
        <MenuButton
          id="focusAfterClose"
          as={Button}
          leftIcon={<Image src={ArrowDownChevron} width="24px" height="24px" />}
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.small}
          color={theme.colors.texts.secondary}
          fontWeight="regular"
          width="100%"
          border="1px solid #ECECEC"
          borderRadius="0px"
          backgroundColor="white"
          textAlign="left"
          padding="16px"
          className="dropdown_button"
          autoFocus={false}
        >
          {selectedValue ? (
            <Text
              color="grey"
              as="span"
              fontSize={theme.fonts.size.small}
              m="6px"
              lineHeight="25px"
            >
              {selectedValue}
            </Text>
          ) : (
            placeholder
          )}
        </MenuButton>
        <Modal
          isOpen={isOpen}
          onClose={handleModalClose}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
          returnFocusOnClose={false}
        >
          <ModalOverlay />
          <ModalContent w={['95%', '481px']} minHeight={['570px', '570px']}>
            <ModalHeader p="16px 24px">
              <Flex justifyContent="space-between">
                <Text fontFamily={theme.fonts.family.body}>
                  {placeholder + ' '}
                  <Text
                    as="span"
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    color={theme.colors.texts.secondary}
                  >
                    (یک مورد به انگلیسی وارد شود)
                  </Text>
                </Text>
                <Flex
                  p="12px"
                  margin="-12px"
                  alignItems="start"
                  cursor="pointer"
                  onClick={handleModalClose}
                >
                  <CloseModal />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody pt="16px">
              <InputGroup _focus={{ border: '1px solid #D9D9D9' }}>
                {/* <InputLeftElement
                  pointerEvents="none"
                  children={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Image
                        src={SearchIcon}
                        w="16px"
                        h="16px"
                        mt="7px"
                        ml="20px"
                      />
                    </motion.div>
                  }
                /> */}
                {!isFocused && (
                  <InputLeftElement pointerEvents="none">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box mt="7px" ml="20px">
                        <SearchIcon width="16px" height="16px" />
                      </Box>
                    </motion.div>
                  </InputLeftElement>
                )}
                <Input
                  lineHeight="normal"
                  dir="ltr"
                  placeholder={
                    !isFocused ? 'Search' : `Search in ${placeholder}`
                  }
                  _placeholder={{
                    alignContent: 'center',
                    color: `${theme.colors.texts.secondary}`,
                    fontFamily: `${theme.fonts.family.body}`,
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: 'normal',
                    transition: 'color 0.2s ease',
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  _focus={{ border: '1px solid #D9D9D9', shadow: 'none' }}
                  p="12px 20px"
                  pl={!isFocused ? '44px' : '16px'}
                  transition="padding-left 0.2s ease"
                  marginBottom="10px"
                  backgroundColor="#F8F9FB"
                  border="none"
                  borderRadius="6px"
                  h="48px"
                  textAlign="left"
                  autoFocus={false}
                />
                {searchQuery && (
                  <InputRightElement padding="0px">
                    <Button
                      marginTop="8px"
                      onClick={clearInput}
                      padding="0px"
                      backgroundColor="transparent"
                      _hover={{
                        backgroundColor: 'transparent',
                        transform: 'scale(1.1)',
                      }}
                    >
                      <ClearTextIcon />
                    </Button>
                  </InputRightElement>
                )}
              </InputGroup>
              {filteredOptions.length === 0 && searchQuery && (
                <Box p={2} textAlign="center">
                  No results found
                </Box>
              )}
              {filteredOptions.map((option) => (
                <React.Fragment key={option.value}>
                  <Box
                    py="0px"
                    px="8px"
                    _hover={{
                      backgroundColor: 'rgba(0, 0, 255,0.02)',
                      borderRadius: '8px',
                    }}
                    onClick={handleItemClick}
                  >
                    <CustomRadiobox
                      isChecked={selectedValue === option.value}
                      label={option.label}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                width="100%"
                onClick={handleConfirm}
                borderRadius="8px"
                backgroundColor={theme.colors.texts.secondary}
                fontFamily={theme.fonts.family.bold}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color={theme.colors.texts.white}
                _hover={{
                  backgroundColor: theme.colors.hover,
                }}
                autoFocus={false}
              >
                تایید
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Menu>
    </Box>
  );
};

export default DropdownCountryCheckboxes;
