import React from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  VStack,
  Divider,
  Button,
  StackDivider,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as WalletIcon } from 'src/assets/mobile/icons/WalletIcon.svg';
import { ReactComponent as AboutUsIcon } from 'src/assets/mobile/icons/AboutUsIcon.svg';
import { ReactComponent as FAQIcon } from 'src/assets/mobile/icons/FAQIcon.svg';
import { ReactComponent as PlansIcon } from 'src/assets/mobile/icons/PlansIcon.svg';
import { ReactComponent as SuggestIcon } from 'src/assets/mobile/icons/SuggestIcon.svg';
import { ReactComponent as CallIcon } from 'src/assets/mobile/icons/CallIcon.svg';
import { ReactComponent as ArrowLeftIcon } from 'src/assets/mobile/icons/ArrowLeftIcon.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/mobile/icons/logout.svg';
import { ReactComponent as BuyPlanIcon } from 'src/assets/mobile/icons/BuyPlanIcon.svg';
import { ReactComponent as PlansCallToActionIcon } from 'src/assets/mobile/icons/PlansCallToActionIcon.svg';
import PagesHeader from 'src/components/mobile/PagesHeader';
import { useNavigate } from 'react-router-dom';
import theme from 'src/theme';
import LogoutModal from 'src/components/mobile/LogoutModal';
import ActionButton from 'src/components/mobile/ActionButton';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleButtonClick = () => {
    console.log('Button clicked');
  };
  return (
    <Box
      bg={theme.colors.mobile.pbg}
      minH="100vh"
      paddingX="16px"
      paddingTop="74px"
    >
      <PagesHeader text=" حساب کاربری" bgc={theme.colors.mobile.pbg} />
      <Flex gap="16px" marginBottom="24px">
        <ActionButton
          text="خرید اشتراک"
          icon={BuyPlanIcon}
          onClick={() => navigate('/purchase-plans')}
        />
        <ActionButton
          text="اشتراک فعال"
          icon={PlansCallToActionIcon}
          onClick={() => navigate('/profile/plans')}
        />
      </Flex>

      <Box bg="white" borderRadius="8px" boxShadow="sm" paddingX="12px">
        <Flex
          paddingY="12px"
          align="center"
          onClick={() => navigate('/profile/transactions')}
          cursor="pointer"
          _active={{
            backgroundColor: 'rgba(226, 232, 240,0.5)',
            borderRadius: '8px',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66,153,225,0.6)',
          }}
        >
          <WalletIcon />
          <Text flex="1" textAlign="right" mr="8px">
            تاریخچه پرداخت ها
          </Text>
          <ArrowLeftIcon width="20px" />
        </Flex>
        <Flex
          paddingY="12px"
          align="center"
          borderTop="1px solid #E2E8F0"
          onClick={() => navigate('/aboutus')}
          cursor="pointer"
          _active={{
            backgroundColor: 'rgba(226, 232, 240,0.5)',
            borderRadius: '8px',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66,153,225,0.6)',
          }}
        >
          <AboutUsIcon />
          <Text flex="1" textAlign="right" mr="8px">
            درباره ما
          </Text>
          <ArrowLeftIcon width="20px" />
        </Flex>

        <Flex
          paddingY="12px"
          align="center"
          borderTop="1px solid #E2E8F0"
          onClick={() => navigate('/FAQ')}
          cursor="pointer"
          _active={{
            backgroundColor: 'rgba(226, 232, 240,0.5)',
            borderRadius: '8px',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66,153,225,0.6)',
          }}
        >
          <FAQIcon />
          <Text flex="1" textAlign="right" mr="8px">
            سوالات متداول
          </Text>
          <ArrowLeftIcon width="20px" />
        </Flex>

        {/* <Flex
          paddingY="12px"
          align="center"
          borderTop="1px solid #E2E8F0"
          onClick={() => navigate('/profile/plans')}
        >
          <PlansIcon />
          <Text flex="1" textAlign="right" mr="8px">
            اشتراک فعال
          </Text>
          <ArrowLeftIcon width="20px" />
        </Flex> */}
        <Flex
          paddingY="12px"
          align="center"
          borderTop="1px solid #E2E8F0"
          onClick={() => onOpen()}
          paddingRight="3px"
          cursor="pointer"
          _active={{
            backgroundColor: 'rgba(226, 232, 240,0.5)',
            borderRadius: '8px',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66,153,225,0.6)',
          }}
        >
          <LogoutIcon />
          <Text flex="1" textAlign="right" mr="8px">
            خروح از حساب کاربری
          </Text>
          <ArrowLeftIcon width="20px" />
        </Flex>
        <Link href="https://t.me/applytory_admin" isExternal>
          <Flex
            paddingY="12px"
            justify="space-between"
            align="center"
            borderTop="1px solid #E2E8F0"
          >
            <Flex align="center">
              <CallIcon />
              <Text ml={2} mr="8px">
                ارتباط با ما
              </Text>
            </Flex>
            <Text>7285 811 910 98+</Text>
          </Flex>
        </Link>
        <Flex paddingY="12px" align="center" borderTop="1px solid #E2E8F0">
          <SuggestIcon />

          <Text flex="1" textAlign="right" mr="8px">
            پیشنهاد به دیگران
          </Text>
          <Button size="sm" colorScheme="blue" mr="8px">
            به زودی
          </Button>
        </Flex>
      </Box>
      <LogoutModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Profile;
