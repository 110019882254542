import { initiatePayment, oneStepPayment } from '../../services/paymentService';
import showToast from '../../services/toastService';

export const handlePayment = async (
  selectedPlan: any,
  voucherCode?: string,
  discountPercentage?: number
) => {
  try {
    let paymentData;
    if (voucherCode) {
      if (discountPercentage == 100) {
        paymentData = await oneStepPayment(
          selectedPlan.planID ? selectedPlan.planID : selectedPlan.id,
          voucherCode
        );
        if (paymentData.body.success) {
          showToast({
            description: 'تخفیف اشتراک بتا فعال شد',
            status: 'success',
          });
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
          return;
        }
      } else {
        paymentData = await initiatePayment(
          selectedPlan.planID ? selectedPlan.planID : selectedPlan.id,
          voucherCode
        );
      }
    } else {
      paymentData = await initiatePayment(
        selectedPlan.planID ? selectedPlan.planID : selectedPlan.id
      );
    }

    window.open(
      `${paymentData.body.ipgUrl}${paymentData.body.inputData.token}`,
      '_blank'
    );
  } catch (error) {
    console.error('Error initiating payment:', error);
    showToast({
      description: 'خطایی در پردازش پرداخت رخ داد. لطفا دوباره تلاش کنید.',
      status: 'error',
    });
  }
};
