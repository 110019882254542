import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';

export const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('AuthContext must be used within an AuthProvider');
  }

  const { isAuthenticated } = authContext;

  // console.log('PrivateRoute: isAuthenticated', isAuthenticated);

  if (!isAuthenticated) {
    // console.log('PrivateRoute: Redirecting to /login');
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};
