import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import theme from '../../theme';
import Vector from '../../assets/icons/Vector.svg';
import Circuit from '../../assets/icons/circuit.svg';
import Gear from '../../assets/icons/Gear.svg';

const AboutUsContainer: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      mb={{ base: '40px', md: '88px' }}
      flexDirection={{ base: 'column', md: 'initial' }}
    >
      <Box w="100%" maxW={{ base: 'unset', md: '970px' }} textAlign="center">
        <Heading
          fontFamily={theme.fonts.family.bold}
          fontWeight="700"
          fontSize={{
            base: '24px',
            md: `clamp(${theme.fonts.size.ultra}, 1.5vw, ${theme.fonts.size.semiultra})`,
          }}
          lineHeight="44px"
          color={theme.colors.texts.dark}
          mb="40px"
        >
          ما مسیر اپلای تحصیلی شما را آسان کرده‌ایم.
        </Heading>
        <Text
          fontFamily={theme.fonts.family.body}
          fontWeight="400"
          fontSize={{
            base: '16px',
            md: `clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`,
          }}
          color={theme.colors.texts.dark}
          lineHeight="24px"
          mb={{ base: '20px', md: 8 }}
        >
          ما با ارائه راه‌حل‌های فناوری پیشرفته و با پشتیبانی از متخصصان، این
          مسیر دشوار را برای شما آسان کرده‌ایم. هم اکنون امکان دسترسی به
          دانشگاه‌ها و اساتید بین‌المللی به راحتی در اختیار شما قرار گرفته شده
          است.
        </Text>
        <Flex
          justify="center"
          align={{ base: 'center', md: 'start' }}
          flexDirection={{ base: 'column', md: 'row' }}
          mt={{ base: '40px', md: '80px' }}
          mb={{ base: '20px', md: '53px' }}
        >
          <Box
            textAlign={{ base: 'center', md: 'right' }}
            mb={{ base: '30px', md: 0 }}
          >
            <Image
              src={Vector}
              h="70px"
              w="70px"
              mb="20px"
              mx={{ base: 'auto', md: '0' }}
            />
            <Heading
              fontFamily={theme.fonts.family.bold}
              fontWeight="800"
              fontSize={{ base: '20px', md: theme.fonts.size.big }}
              color={theme.colors.texts.dark}
              lineHeight="38px"
              mb="20px"
            >
              امکان جستجو هدفمند
            </Heading>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.dark}
              lineHeight="24px"
            >
              بر اساس علایق، استعداد و رزومه تحصیلی شما
            </Text>
          </Box>
          <Box
            textAlign={{ base: 'center', md: 'right' }}
            bg={theme.colors.primary}
            color="white"
            p={{ base: '20px', md: '25px 50px 35px 30px' }}
            borderRadius="8px"
            boxShadow="0px 10px 40px 0px #22222226"
            mx={{ base: 'auto', md: '40px' }}
            mb={{ base: '30px', md: 0 }}
          >
            <Image
              src={Circuit}
              h="70px"
              w="70px"
              mb="20px"
              mx={{ base: 'auto', md: '0' }}
            />
            <Heading
              fontFamily={theme.fonts.family.bold}
              fontWeight="800"
              fontSize={{ base: '20px', md: theme.fonts.size.big }}
              color={theme.colors.texts.white}
              lineHeight="38px"
              mb="20px"
            >
              پشتیبانی
            </Heading>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.white}
              lineHeight="24px"
            >
              Some networks believe that by only offering.
            </Text>
          </Box>
          <Box
            textAlign={{ base: 'center', md: 'right' }}
            mb={{ base: '20px', md: 0 }}
            mx={{ base: 'auto', md: '0' }}
          >
            <Image
              src={Gear}
              h="70px"
              w="70px"
              mb="20px"
              mx={{ base: 'auto', md: '0' }}
            />
            <Heading
              fontFamily={theme.fonts.family.bold}
              fontWeight="800"
              fontSize={{ base: '20px', md: theme.fonts.size.big }}
              color={theme.colors.texts.dark}
              lineHeight="38px"
              mb="20px"
            >
              دسترسی رایگان به اساتید
            </Heading>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.semismall}
              color={theme.colors.texts.dark}
              lineHeight="24px"
            >
              دسترسی رایگان به لیست محتوایی از اساتید و
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AboutUsContainer;
