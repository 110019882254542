import React, { useState, useEffect, useContext } from 'react';
import { ChakraProvider, Box, Text, Container } from '@chakra-ui/react';
import theme from '../theme';
import { getAllReports } from '../services/reportService';
import Loader from '../components/Loader';
import { AuthContext } from '../context/AuthContext';
import NoReportFound from 'src/components/mobile/NoReportFound';
import ReportsList from 'src/components/mobile/ReportsList';
import PagesHeader from 'src/components/mobile/PagesHeader';

interface Report {
  createdDate: string;
  id: string;
  reportId: string;
  country: string;
  interestList: string[];
  reportTypeId: number;
  status: string;
}

const LastReports: React.FC = () => {
  const [reportsData, setReportsData] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!authContext) {
      return;
    }

    const fetchReports = async () => {
      try {
        const response = await getAllReports(0, 100);
        let reports: Report[] = response.body.content.map((report: any) => ({
          id: report.id,
          reportId: report.reportId,
          createdDate: report.createdDate,
          country: report.country,
          interestList: report.interestList,
          reportTypeId: report.reportTypeId,
          status: report.status,
        }));

        reports = reports.sort(
          (a, b) =>
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
        );

        setReportsData(reports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [authContext]);

  if (!authContext) {
    return (
      <Box
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        pt="58px"
        backgroundColor={theme.colors.mobile.pbg}
      >
        <Container>
          <Text
            textAlign="center"
            fontFamily={theme.fonts.family.body}
            fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
            lineHeight="37px"
            color="#252C58"
            mb="48px"
          >
            Unable to determine authentication status. Please try again later.
          </Text>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      textAlign="center"
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="56px"
      px="16px"
    >
      <PagesHeader text="گزارش‌های من" bgc={theme.colors.mobile.pbg} />
      {loading ? (
        <Loader color1="#56A2FF" color2="#FFF" size={50} />
      ) : (
        <>
          {reportsData.length === 0 ? (
            <NoReportFound />
          ) : (
            <ReportsList reportsData={reportsData} />
          )}
        </>
      )}
    </Box>
  );
};

export default LastReports;
