import React, { useEffect, useState } from 'react';
import {
  VStack,
  Text,
  Box,
  Flex,
  PinInput,
  PinInputField,
  Button,
} from '@chakra-ui/react';
import theme from '../../theme';

interface AuthStepTwoProps {
  code: string;
  setCode: (code: string) => void;
  handleCodeSubmit: () => void;
  onTimerEnd: () => void; // Add this prop
}

const AuthStepTwo: React.FC<AuthStepTwoProps> = ({
  code,
  setCode,
  handleCodeSubmit,
  onTimerEnd,
}) => {
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          onTimerEnd(); // Call the callback when the timer reaches zero
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [onTimerEnd]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <VStack alignItems="flex-start" spacing={0}>
      <Text
        fontFamily={theme.fonts.family.body}
        fontWeight="medium"
        fontSize={`clamp(${theme.fonts.size.small}, 2vw, ${theme.fonts.size.semismall})`}
        color={theme.colors.texts.semiprimary}
        marginBottom="68px"
      >
        کد ارسال شده به ایمیل خود را وارد نمایید
      </Text>
      <Box
        textAlign="center"
        justifyContent="center"
        alignContent="center"
        width="100%"
        dir="ltr"
        mb="16px"
      >
        <Flex justifyContent="space-between" mb="8px">
          <PinInput value={code} onChange={setCode} type="alphanumeric">
            <PinInputField
              placeholder=""
              w="56px"
              h="56px"
              border="solid 1.6px #E4E4E4"
              borderRadius="16px"
              _placeholder={{ color: 'transparent' }}
            />
            <PinInputField
              placeholder=""
              w="56px"
              h="56px"
              border="solid 1.6px #E4E4E4"
              borderRadius="16px"
              _placeholder={{ color: 'transparent' }}
            />
            <PinInputField
              placeholder=""
              w="56px"
              h="56px"
              border="solid 1.6px #E4E4E4"
              borderRadius="16px"
              _placeholder={{ color: 'transparent' }}
            />
            <PinInputField
              placeholder=""
              w="56px"
              h="56px"
              border="solid 1.6px #E4E4E4"
              borderRadius="16px"
              _placeholder={{ color: 'transparent' }}
            />
            <PinInputField
              placeholder=""
              w="56px"
              h="56px"
              border="solid 1.6px #E4E4E4"
              borderRadius="16px"
              _placeholder={{ color: 'transparent' }}
            />
            <PinInputField
              placeholder=""
              w="56px"
              h="56px"
              border="solid 1.6px #E4E4E4"
              borderRadius="16px"
              _placeholder={{ color: 'transparent' }}
            />
          </PinInput>
        </Flex>
        <Text
          fontWeight="700"
          fontSize={`clamp(${theme.fonts.size.small}, 2vw, ${theme.fonts.size.semismall})`}
          color={theme.colors.texts.semiprimary}
        >
          {formatTime(timer)}
        </Text>
      </Box>
      <Button
        onClick={handleCodeSubmit}
        width="100%"
        backgroundColor={theme.colors.secondary}
        borderRadius="8px"
        padding="10px 0px"
        h="48px"
        fontFamily={theme.fonts.family.body}
        fontWeight="medium"
        fontSize={`clamp(${theme.fonts.size.lil}, 2vw, ${theme.fonts.size.small})`}
        color={theme.colors.texts.white}
        _hover={{
          backgroundColor: `${theme.colors.hover}`,
          color: `${theme.colors.texts.white}`,
        }}
      >
        تایید
      </Button>
    </VStack>
  );
};

export default AuthStepTwo;
