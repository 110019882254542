import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Text, Box } from '@chakra-ui/react';
import theme from '../../theme';
interface Professor {
  id: number;
  author: string;
  scholarId: string | null;
  urlPicture: string;
  affiliation: string;
  email: string | null;
  interests: string[];
  authorCitations: number;
  university: string | null;
  country: string;
  universityWorldRank: number;
  universityCountryRank: number;
  interestSimilarityScore: number;
}

interface ProfsTableProps {
  data: Professor[];
  limit: number;
}

const ProfsTable: React.FC<ProfsTableProps> = ({ data, limit }) => {
  const sortedData = [...data].sort(
    (a, b) => b.interestSimilarityScore - a.interestSimilarityScore
  );

  return (
    <Box p={{ base: '2', md: '4' }} className="scroll-table">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize="24px"
              fontFamily="body"
              p="0px"
              textAlign="center"
              py="34px"
            ></Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              fontFamily={theme.fonts.family.body}
              color="rgba(0,0,0,0.5)"
              textAlign="center"
              pr="0px"
              py="34px"
            >
              نام استاد
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              fontFamily={theme.fonts.family.body}
              color="rgba(0,0,0,0.5)"
              textAlign="center"
              py="34px"
            >
              دانشگاه
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              fontFamily={theme.fonts.family.body}
              color="rgba(0,0,0,0.5)"
              textAlign="center"
              py="34px"
            >
              رنک دانشگاه در کشور
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.body}
              color="rgba(0,0,0,0.5)"
              textAlign="center"
              py="34px"
            >
              رنک دانشگاه در جهان
            </Th>
            {/* <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.body}
              color="rgba(0,0,0,0.5)"
              textAlign="center"
              py="34px"
            >
              علایق اساتید
            </Th> */}
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.body}
              color="rgba(0,0,0,0.5)"
              textAlign="center"
              py="34px"
            >
              تعداد ارجاعات به مقاله
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.slice(0, limit).map((prof, index) => (
            <Tr key={index} textAlign="center" className="ltr-direction">
              <Td
                fontFamily={theme.fonts.family.body}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                p="0px"
                borderBottom={
                  index === data.length - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                <Box
                  bg="#D9D9D9"
                  borderRadius="50%"
                  width="23px"
                  height="23px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text lineHeight="40px" mb="-2px">
                    {index + 1}
                  </Text>
                </Box>
              </Td>
              <Td
                fontFamily={'sans-serif'}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                pr="0px"
                py="32px"
                borderBottom={
                  index === limit - 1 ? 'none' : '1px solid #E2E8F0'
                }
                onClick={() => {
                  const term = prof.author || 'N/A';
                  window.open(
                    `https://google.com/search?q=professor ${encodeURIComponent(
                      term
                    )}`,
                    '_blank'
                  );
                }}
                style={{ cursor: 'pointer' }}
                _hover={{ color: theme.colors.hover }}
              >
                {/*نام استاد */}
                <Text className="ltr-direction">{prof.author || 'N/A'}</Text>
              </Td>
              <Td
                fontFamily={'sans-serif'}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                py="32px"
                borderBottom={
                  index === limit - 1 ? 'none' : '1px solid #E2E8F0'
                }
                className="ltr-direction"
              >
                <Text className="ltr-direction">
                  {prof.affiliation || 'N/A'}
                </Text>
              </Td>
              <Td
                fontFamily={'sans-serif'}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                py="32px"
                borderBottom={
                  index === limit - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {prof.universityCountryRank || 'N/A'}
              </Td>
              <Td
                fontFamily={'sans-serif'}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                py="32px"
                borderBottom={
                  index === limit - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {prof.universityWorldRank || 'N/A'}
              </Td>
              {/* علایق اساتید
              <Td
                fontFamily={'sans-serif'}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                py="32px"
                borderBottom={
                  index === limit - 1 ? 'none' : '1px solid #E2E8F0'
                }
                className="ltr-direction"
              >
                {prof.interests.map((interest, idx) => (
                  <Text key={idx}>{interest}</Text>
                ))}
              </Td> */}
              <Td
                fontFamily={'sans-serif'}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.small})`}
                color="rgba(64, 64, 64, 1)"
                textAlign="center"
                py="32px"
                borderBottom={
                  index === limit - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {prof.authorCitations || 'N/A'}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ProfsTable;
