import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Text, Flex, Image, Stack } from '@chakra-ui/react'; // Import Stack for better responsive layout
import { useParams } from 'react-router-dom';
import CalanderIcon from '../../assets/icons/CalanderIcon.svg';
import theme from '../../theme';
import FilterCheckbox from './FilterCheckbox';
import FilterDropDown from './FilterDropDown';
import ProfsTable from './ProfsTable';
import { getReportWithId } from '../../services/reportService';
import ListPlans from './ListPlans';

interface Professor {
  id: number;
  author: string;
  scholarId: string | null;
  urlPicture: string;
  affiliation: string;
  email: string | null;
  interests: string[];
  authorCitations: number;
  university: string | null;
  country: string;
  universityWorldRank: number;
  universityCountryRank: number;
  interestSimilarityScore: number;
}

interface ReportResponse {
  applyResultList: Professor[];
}

interface FreeProfResProps {
  country: string;
  createdDate: string;
  interestList: string[];
}

const extractDayAndMonth = (dateStr: string) => {
  const [yearMonthDay, _dayOfWeek] = dateStr.split(', ');
  const [year, month, day] = yearMonthDay.split(' ');
  return `${day} ${month}`;
};

const FreeProfRes: React.FC<FreeProfResProps> = ({
  country,
  createdDate,
  interestList,
}) => {
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [professors, setProfessors] = useState<Professor[]>([]);
  const [loading, setLoading] = useState(true);
  const [allInterests, setAllInterests] = useState<string[]>([]);
  const { reportId } = useParams();
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (reportId) {
          // console.log(`Fetching report data for reportId: ${reportId}`);
          const response = await getReportWithId(reportId);
          if (response && response.applyResultList) {
            const data: ReportResponse = response;
            setProfessors(data.applyResultList);
            setLoading(false);
            // console.log('Fetch successful. Professors:', data.applyResultList);

            const interests = data.applyResultList.flatMap(
              (prof) => prof.interests
            );
            const uniqueInterests = Array.from(new Set(interests));
            setAllInterests(uniqueInterests);
          } else {
            console.error('Error: No applyResultList found in response');
          }
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchData();
  }, [reportId]);

  const handleStatusChange = useCallback(
    (interest: string, status: boolean) => {
      setSelectedInterests((prevSelected) => {
        const normalizedInterest = normalizeString(interest);
        if (status && !prevSelected.includes(normalizedInterest)) {
          const newSelected = [...prevSelected, normalizedInterest];
          console.log(
            `Added interest: ${interest}. New selection:`,
            newSelected
          );
          return newSelected;
        } else if (!status && prevSelected.includes(normalizedInterest)) {
          const newSelected = prevSelected.filter(
            (item) => item !== normalizedInterest
          );
          console.log(
            `Removed interest: ${interest}. New selection:`,
            newSelected
          );
          return newSelected;
        } else {
          return prevSelected;
        }
      });
    },
    []
  );

  const handleConChange = useCallback((interest: string, status: boolean) => {
    // Implement if needed
  }, []);

  const normalizeString = (str: string) => str.trim().toLowerCase();

  const filteredProfessors = useMemo(() => {
    if (selectedInterests.length === 0) {
      return professors;
    }

    console.log('Filtering professors with interests:', selectedInterests);

    return professors.filter((professor) => {
      const lowerCaseProfInterests = professor.interests.map((profInterest) =>
        normalizeString(profInterest)
      );
      return selectedInterests.every((interest) => {
        const lowerCaseInterest = normalizeString(interest);
        return lowerCaseProfInterests.includes(lowerCaseInterest);
      });
    });
  }, [professors, selectedInterests]);

  const handleDropdownSelect = useCallback(
    (interest: string | null) => {
      console.log('interest in handleDropdownSelect Changed to : ', interest);

      setSelectedInterests((prevSelected) => {
        if (interest) {
          const normalizedInterest = normalizeString(interest);
          let newSelected;

          if (interestList.map(normalizeString).includes(normalizedInterest)) {
            newSelected = prevSelected.includes(normalizedInterest)
              ? prevSelected.filter((item) => item !== normalizedInterest)
              : [...prevSelected, normalizedInterest];
          } else {
            const fromInitialList = prevSelected.filter((item) =>
              interestList.map(normalizeString).includes(normalizeString(item))
            );
            newSelected = [...fromInitialList, normalizedInterest];
          }

          console.log(`Dropdown selected interest: ${interest}`);
          console.log(`New selected interests:`, newSelected);
          return newSelected;
        } else {
          return [];
        }
      });
    },
    [interestList]
  );

  const filteredAllInterests = allInterests.filter(
    (interest) =>
      !interestList.map(normalizeString).includes(normalizeString(interest))
  );

  return (
    <>
      <Box overflowX="auto" p={{ base: '4', md: '64px 32px' }} bg="white">
        <Box
          mb="34px"
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'start', md: 'center' }}
          justifyContent={{ base: 'space-around', md: 'space-between' }}
        >
          <Flex gap="16px" mb={{ base: '16px', md: '0' }}>
            <Flex
              gap="8px"
              bg="rgba(192, 192, 192, 0.2)"
              p="12px 16px 13px 16px"
              borderRadius="8px"
              border="1px solid rgba(213, 217, 221, 1)"
            >
              <Image src={CalanderIcon} width={{ base: '12px', md: '13px' }} />
              <Text
                mb="-4px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={{
                  base: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                  md: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                }}
                lineHeight="21px"
                color="rgba(37, 44, 88, 0.5)"
              >
                {extractDayAndMonth(
                  new Intl.DateTimeFormat('fa-IR', {
                    dateStyle: 'full',
                  }).format(new Date(createdDate))
                )}
              </Text>
            </Flex>
          </Flex>

          <Flex
            gap="16px"
            flexDirection={{ base: 'column', md: 'row' }}
            mt={{ base: '16px', md: '0' }}
          >
            <Flex gap="16px">
              <FilterCheckbox
                label={country}
                onStatusChange={(status) => handleConChange(country, status)}
                initialChecked={true}
              />
              {interestList.map((interest) => (
                <FilterCheckbox
                  key={interest}
                  label={interest}
                  onStatusChange={(status) =>
                    handleStatusChange(interest, status)
                  }
                />
              ))}
            </Flex>
            {/* DropDown For Filter instrests */}
            {/* <Box mt={{ base: '16px', md: '0' }}>
              <FilterDropDown
                interests={filteredAllInterests}
                onSelect={handleDropdownSelect}
              />
            </Box> */}
          </Flex>
        </Box>
        <ProfsTable data={filteredProfessors.slice(0, limit)} limit={limit} />
      </Box>

      <ListPlans />
    </>
  );
};

export default FreeProfRes;
