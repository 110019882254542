import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Spinner,
  Button,
  VStack,
  Icon,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { verifyPayment } from '../services/paymentService';
import theme from '../theme';
import { getPlanData } from '../services/priceService';

const PaymentResult: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
  const [transactionDetails, setTransactionDetails] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [plans, setPlans] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);

  const formatJalaliDate = (gregorianDate: string) => {
    const date = new Date(gregorianDate);
    const formatter = new Intl.DateTimeFormat('fa-IR', {
      dateStyle: 'full',
      timeStyle: 'short',
    });
    const formattedDate = formatter.format(date);
    const dateParts = formattedDate.split(' ');
    const day = dateParts[0].replace(',', '');
    const month = dateParts[1].replace(',', '');
    const year = dateParts[2].replace(',', '');

    return `${formattedDate}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authority = urlParams.get('Authority');
    const status = urlParams.get('Status');

    if (authority && status) {
      verifyPayment(authority, status)
        .then(async (response) => {
          if (response.body.success) {
            const planData = await getPlanData();
            setPlans(planData);
            console.log('planData');
            console.log(planData);

            const selectedPlan = planData.find(
              (plan: any) => plan.id === response.body.reportTypeId
            );
            console.log('selectedPlan');
            console.log(selectedPlan);

            setSelectedPlan(selectedPlan);
            setTransactionDetails(response.body);
            setPaymentSuccess(true);
          } else {
            setErrorMessage(
              response.body.message || 'Payment verification failed.'
            );
            setPaymentSuccess(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage('An error occurred while verifying the payment.');
          setPaymentSuccess(false);
          setLoading(false);
        });
    } else {
      setErrorMessage('Missing authority or status in the callback URL.');
      setPaymentSuccess(false);
      setLoading(false);
    }
  }, [location.search]);

  return (
    <Box textAlign="center" mt={10} p={4}>
      {loading ? (
        <>
          <Spinner size="xl" />
          <Text
            fontFamily={theme.fonts.family.bold}
            fontWeight="400"
            fontSize={`clamp(${theme.fonts.size.semismall}, 1.5vw, ${theme.fonts.size.big})`}
            mt={2}
          >
            در حال بررسی پرداخت شما...
          </Text>
        </>
      ) : (
        <VStack spacing={6}>
          {paymentSuccess ? (
            <>
              <Icon as={CheckCircleIcon} w={10} h={10} color="green.500" />
              <Text
                mt={2}
                color="green.500"
                fontFamily={theme.fonts.family.bold}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
              >
                پرداخت شما با موفقیت انجام شد
              </Text>
            </>
          ) : (
            <>
              <Icon as={CheckCircleIcon} w={10} h={10} color="red.500" />
              <Text
                mt={2}
                color="red.500"
                fontFamily={theme.fonts.family.bold}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
              >
                پرداخت شما با خطا انجام شد
              </Text>
            </>
          )}

          <Box
            bg="white"
            w="661px"
            p="32px"
            borderRadius="16px"
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15)"
          >
            <Button
              bg="#404040"
              borderRadius="12px"
              w="100%"
              h="auto"
              paddingY="18px"
              marginBottom="32px"
              color="white"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              cursor="default"
              _hover={{ backgroundColor: '#404040' }}
            >
              جزئیات تراکنش
            </Button>
            <Flex justifyContent="space-between" textAlign="right">
              <Text
                fontFamily={theme.fonts.family.bold}
                fontWeight="500"
                fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
                color="#333D55"
                mb="8px"
              >
                {selectedPlan?.id === 1
                  ? 'اشتراک بتا - یک هفته'
                  : 'اشتراک رایگان'}
              </Text>
              <Text
                fontFamily={theme.fonts.family.body}
                fontWeight="500"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                color="#333D55"
                //  dir="ltr"
              >
                {transactionDetails
                  ? formatJalaliDate(transactionDetails?.transactionDate)
                  : ''}
              </Text>
            </Flex>
            <Text
              fontFamily={theme.fonts.family.bold}
              fontWeight="700"
              fontSize={`clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`}
              color="#333D55"
              mb="24px"
              textAlign="right"
            >
              {selectedPlan?.price}
              <Text
                as="span"
                fontFamily={theme.fonts.family.body}
                fontWeight="300"
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              >
                ریال
              </Text>
            </Text>
            <Divider my="24px" />
            <Box>
              {/* <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                color="#0C0B0B"
              >
                <Text>درصد تخفیف:</Text>
                <Text>۱۸ درصد</Text>
              </Flex> */}
              <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                color="#0C0B0B"
              >
                <Text>شماره پیگیری:</Text>
                <Text dir="ltr">{transactionDetails?.straceNumber}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                color="#0C0B0B"
              >
                <Text>شماره کارت:</Text>
                <Text dir="ltr">{transactionDetails?.maskedCardNumber}</Text>
              </Flex>
              <Flex
                justifyContent="space-between"
                mb="24px"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                color="#0C0B0B"
              >
                <Text>بابت:</Text>
                <Text>اشتراک بتا</Text>
              </Flex>
            </Box>
          </Box>
          <Flex w="661px" gap={4}>
            <Button
              bg="#404040"
              borderRadius="12px"
              w="100%"
              h="auto"
              paddingY="18px"
              marginBottom="32px"
              color="white"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              _hover={{ backgroundColor: '#333' }}
              onClick={() => navigate('/')}
            >
              بازگشت به صفحه اصلی
            </Button>
          </Flex>
        </VStack>
      )}
    </Box>
  );
};

export default PaymentResult;
