import React, { useContext } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Image,
  List,
  ListItem,
  ListIcon,
  OrderedList,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import theme from '../theme';
import { AppContext } from '../context/AppContext';

const MoreDetails = () => {
  const context = useContext(AppContext);

  if (!context) {
    return null; // or some fallback UI
  }

  const { moreDetailsContent } = context;
  const { heading, steps } = moreDetailsContent;

  return (
    <Box
      mx={['16px', '32px', '64px', '120px']}
      mt={['24px', '48px', '68px']}
      mb={['24px', '48px', '68px']}
      fontFamily={theme.fonts.family.body}
      fontWeight="400"
      fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
      color="#0C0B0B"
    >
      <VStack
        spacing={8}
        align="stretch"
        textAlign="right"
        px={['20px', '40px', '50px']}
        borderRadius="16px"
      >
        <Heading
          fontFamily={theme.fonts.family.bold}
          fontWeight="500"
          fontSize={`clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`}
          mb={5}
          bg="#3080E233"
          p={['20px', '30px', '40px']}
          borderRadius="16px"
          textAlign="center"
        >
          {heading}
        </Heading>

        {steps.map((step, index) => (
          <VStack key={index} spacing={8} align="start">
            <Heading fontSize="2xl" fontFamily={theme.fonts.family.bold}>
              {step.title}
            </Heading>
            <OrderedList spacing={3}>
              {step.steps.map((stepDetail, index) => (
                <ListItem key={index}>
                  {stepDetail}
                  {index === 1 && (
                    <>
                      <List spacing={2} mt={2}>
                        {step.resume.items.map((item, index) => (
                          <ListItem key={index}>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            {item}
                          </ListItem>
                        ))}
                      </List>
                      <Box display="flex" justifyContent="center" mt={4} mb={4}>
                        <Image
                          width={['200px', '300px', '400px']}
                          src={step.resume.image}
                          alt="Resume Example"
                          loading="lazy"
                          borderRadius="md"
                        />
                      </Box>
                    </>
                  )}
                  {index === 3 && (
                    <>
                      <Text mt={2}>مثال‌هایی از علایق پژوهشی:</Text>
                      <List spacing={2} mt={2}>
                        {step.researchInterests.map((interest, index) => (
                          <ListItem key={index}>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            اگر در حوزه {interest.field} فعالیت می‌کنید،
                            می‌توانید از کلماتی مانند{' '}
                            {interest.keywords.join(', ')} استفاده کنید.
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </ListItem>
              ))}
            </OrderedList>
          </VStack>
        ))}
      </VStack>
    </Box>
  );
};

export default MoreDetails;
