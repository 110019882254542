import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone, FileRejection, DropEvent, Accept } from 'react-dropzone';
import { Box, Text, Flex, Progress, Circle } from '@chakra-ui/react';
import { ReactComponent as FileUploadBlueIcon } from 'src/assets/mobile/icons/FileUploadBlueIcon.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as DocumentIcon } from 'src/assets/icons/DocumentIcon.svg';
import { ReactComponent as TickCircleIcon } from 'src/assets/icons/TickCircleIcon.svg';

import theme from 'src/theme';

interface FileUploadProps {
  onFileUpload: (file: File) => void;
}

type UploadStatus = 'idle' | 'uploading' | 'success' | 'error';

const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload }) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('idle');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [tempSuccess, setTempSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [retryAttempted, setRetryAttempted] = useState(false);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setFileUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [file]);

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      if (fileRejections.length > 0) {
        const errorMessages = fileRejections
          .map((rejection) => {
            const { errors } = rejection;
            return errors
              .map((error) => {
                if (error.code === 'file-too-large') {
                  return 'حجم فایل بیشتر از حد مجاز است';
                } else if (error.code === 'file-invalid-type') {
                  return 'فقط فایل‌های PDF قابل قبول هستند';
                } else {
                  return 'خطای نامشخص در بارگذاری فایل';
                }
              })
              .join(', ');
          })
          .join(', ');
        setErrorMessage(errorMessages);
        return;
      }

      if (acceptedFiles.length > 0) {
        const uploadedFile = acceptedFiles[0];
        setFile(uploadedFile);
        setUploadStatus('uploading');
        setErrorMessage(null);
        setRetryAttempted(false);
        simulateUpload(uploadedFile);
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10 MB
    accept: { 'application/pdf': ['.pdf'] },
  });

  const simulateUpload = (uploadedFile: File) => {
    const uploadDuration = 3000; // 3 seconds
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
        // Simulate success or error
        const isSuccess = Math.random() > 0.2;
        if (isSuccess) {
          setUploadStatus('success');
          setTempSuccess(true);
          onFileUpload(uploadedFile);
          setTimeout(() => {
            setTempSuccess(false);
          }, 1000);
        } else {
          if (retryAttempted) {
            setUploadStatus('error');
            setErrorMessage('خطا در آپلود فایل. لطفا دوباره تلاش کنید.');
          } else {
            setRetryAttempted(true);
            setUploadProgress(0);
            simulateUpload(uploadedFile);
          }
        }
      }
    }, uploadDuration / 10);
  };

  const handleRetry = () => {
    if (file) {
      setUploadStatus('uploading');
      setUploadProgress(0);
      setErrorMessage(null);
      simulateUpload(file);
    }
  };

  const handleRemove = () => {
    setFile(null);
    setUploadStatus('idle');
    setUploadProgress(0);
    setTempSuccess(false);
    setErrorMessage(null);
  };

  return (
    <Box
      width="100%"
      backgroundColor="#ffff"
      padding="12px 16px"
      borderRadius="8px"
    >
      <Text
        fontFamily={theme.fonts.family.body}
        fontSize={theme.fonts.size.small}
        color={theme.colors.texts.secondary}
        fontWeight="500"
        marginBottom="16px"
      >
        افزودن رزومه
      </Text>
      <Box width="100%" marginBottom="16px">
        {uploadStatus === 'idle' && (
          <Flex
            {...getRootProps()}
            p={5}
            backgroundColor="#ebebf0"
            borderWidth="1px"
            borderColor="#8E8E93"
            padding="16px"
            borderStyle="dashed"
            borderRadius="6px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            cursor="pointer"
            _hover={{ borderColor: 'blue.500' }}
          >
            <input {...getInputProps()} />
            <FileUploadBlueIcon width="44px" height="44px" />
            <Text
              fontFamily={theme.fonts.family.body}
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              color={theme.colors.texts.semidark}
              mt="12px"
              mb="4px"
              fontWeight="500"
            >
              افزودن فایل رزومه
            </Text>
          </Flex>
        )}

        {uploadStatus === 'uploading' && file && (
          <Box p={5} borderWidth="1px" borderColor="#CACACA" borderRadius="6px">
            <Flex alignItems="start" justifyContent="space-between">
              <Flex alignItems="start">
                <DocumentIcon width="20px" />
                <Box mr="12px">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    color="#353535"
                  >
                    {file.name}
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.lil}
                    color="#989692"
                  >{`${(file.size / 1024).toFixed(2)} KB`}</Text>
                </Box>
              </Flex>
              <TrashIcon cursor="pointer" width="20px" onClick={handleRemove} />
            </Flex>
            <Flex gap="12px" mt="11px" align="center">
              <Progress
                value={uploadProgress}
                size="sm"
                borderRadius="8px"
                sx={{
                  '& > div': {
                    backgroundColor: '#381DDB',
                  },
                }}
                width="90%"
              />
              <Text
                fontFamily={theme.fonts.family.body}
                fontSize={theme.fonts.size.lil}
                color="#989692"
                display="inline-block"
                width="10%"
              >
                {uploadProgress}%
              </Text>
            </Flex>
          </Box>
        )}

        {tempSuccess && file && (
          <Box p={5} borderWidth="1px" borderColor="#CACACA" borderRadius="6px">
            <Flex alignItems="start" justifyContent="space-between">
              <Flex alignItems="start">
                <DocumentIcon width="20px" />
                <Box textAlign="right" mr="12px">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    color="#353535"
                  >
                    {file.name}
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.lil}
                    color="#989692"
                  >{`${(file.size / 1024).toFixed(2)} KB`}</Text>
                </Box>
              </Flex>
              <TickCircleIcon width="20px" />
            </Flex>
            <Flex gap="12px" mt="11px" align="center">
              <Progress
                value={100}
                size="sm"
                borderRadius="8px"
                sx={{
                  '& > div': {
                    backgroundColor: '#50C878',
                  },
                }}
                width="90%"
              />
              <Text
                fontFamily={theme.fonts.family.body}
                fontSize={theme.fonts.size.lil}
                color="#989692"
                display="inline-block"
                width="10%"
              >
                {uploadProgress}%
              </Text>
            </Flex>
          </Box>
        )}

        {!tempSuccess && uploadStatus === 'success' && file && (
          <Box p={5} borderWidth="1px" borderColor="#CACACA" borderRadius="6px">
            <Flex alignItems="start" justifyContent="space-between">
              <Flex alignItems="start">
                <DocumentIcon width="20px" />
                <Box mr="12px">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    color="#353535"
                  >
                    {file.name}
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.lil}
                    color="#989692"
                  >{`${(file.size / 1024).toFixed(2)} KB`}</Text>
                  {fileUrl && (
                    <Text
                      as="a"
                      href={fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.small}
                      fontWeight="600"
                      color="#381DDB"
                      cursor="pointer"
                    >
                      مشاهده فایل
                    </Text>
                  )}
                </Box>
              </Flex>

              <TrashIcon cursor="pointer" width="20px" onClick={handleRemove} />
            </Flex>
          </Box>
        )}

        {uploadStatus === 'error' && file && (
          <>
            <Box
              p={5}
              borderWidth="1px"
              borderColor="#CACACA"
              borderRadius="6px"
            >
              <Flex alignItems="start" justifyContent="space-between">
                <Flex alignItems="start">
                  <DocumentIcon width="20px" />
                  <Box mr="12px">
                    <Text
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.small}
                      color="#C92519"
                    >
                      {errorMessage && errorMessage}
                    </Text>
                    <Text
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.lil}
                      color="#C92519"
                    >
                      {file.name}
                    </Text>
                    <Text
                      fontFamily={theme.fonts.family.body}
                      fontSize={theme.fonts.size.small}
                      fontWeight="600"
                      color="#C92519"
                      cursor="pointer"
                      onClick={handleRetry}
                    >
                      دوباره تلاش کنید
                    </Text>
                  </Box>
                </Flex>

                <TrashIcon
                  cursor="pointer"
                  width="20px"
                  onClick={handleRemove}
                />
              </Flex>
            </Box>
          </>
        )}
      </Box>
      <Text
        as="span"
        fontFamily={theme.fonts.family.body}
        fontSize={theme.fonts.size.small}
        color={theme.colors.texts.secondary}
        fontWeight="500"
      >
        شرایط فایل
      </Text>
      <Flex alignItems="center" mb="8px">
        <Circle
          size="20px"
          color={theme.colors.mobile.mobileblue}
          bg="rgba(235, 97, 98, 0.1)"
          ml="8px"
        >
          <Text marginTop="3px"> 1</Text>
        </Circle>
        <Text
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.small}
          color={theme.colors.texts.secondary}
          fontWeight="400"
        >
          حداکثر حجم فایل 2 مگابایت
        </Text>
      </Flex>
      <Flex alignItems="center" mb="8px">
        <Circle
          size="20px"
          color={theme.colors.mobile.mobileblue}
          bg="rgba(235, 97, 98, 0.1)"
          ml="8px"
        >
          <Text marginTop="3px">2</Text>
        </Circle>
        <Text
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.small}
          color={theme.colors.texts.secondary}
          fontWeight="400"
        >
          فایل باید pdf باشد
        </Text>
      </Flex>
    </Box>
  );
};

export default FileUpload;
