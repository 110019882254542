import React from 'react';
import {
  Box,
  Text,
  Button,
  Divider,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';

export interface PlanData {
  title: string;
  price: string;
  duration: string;
  features: string[];
  planID: number;
}

interface PlanBoxProps {
  title: string;
  price: string;
  duration: string;
  features: string[];
  onClick: () => void;
}

const PlanBox: React.FC<PlanBoxProps> = ({
  title,
  price,
  duration,
  features,
  onClick,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      w="100%"
      bg="white"
      borderRadius="16px"
      p="23px 29px"
      boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15)"
      textAlign="right"
      mb="10px"
    >
      <Text
        fontFamily={theme.fonts.family.bold}
        fontWeight="500"
        fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
        color="#333D55"
        lineHeight="28px"
        mb="4px"
      >
        {title}
      </Text>
      <Text
        fontFamily={theme.fonts.family.body}
        fontWeight="400"
        fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
        color="#333D55"
        mb="10px"
      >
        عضویت
      </Text>
      <Text
        fontFamily={theme.fonts.family.bold}
        fontWeight="700"
        fontSize={`clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`}
        color="#333D55"
        mb="10px"
      >
        {price}
        <Text
          as="span"
          fontFamily={theme.fonts.family.body}
          fontWeight="300"
          fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
        >
          ریال
        </Text>
      </Text>
      {/* <Text
        fontFamily={theme.fonts.family.body}
        fontWeight="700"
        fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
        color="#333D55"
        mb="50px"
      >
        {duration}
      </Text> */}
      <Divider mb="24px" borderColor="#AEAEAE" />
      <UnorderedList>
        {features.map((feature, index) => (
          <ListItem
            key={index}
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={theme.fonts.size.semismall}
            color="#333D55"
            mb="16px"
          >
            {feature}
          </ListItem>
        ))}
      </UnorderedList>
      <Button
        bg="#404040"
        borderRadius="74px"
        w="100%"
        h="auto"
        paddingY="18px"
        color="white"
        fontFamily={theme.fonts.family.body}
        fontWeight="400"
        fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
        _hover={{
          backgroundColor: title === 'اشتراک رایگان' ? '#404040' : '#333',
        }}
        cursor={title === 'اشتراک رایگان' ? 'pointer' : 'pointer'}
        onClick={onClick}
      >
        {title === 'اشتراک رایگان' ? 'رایگان' : ' دریافت اشتراک'}
      </Button>
    </Box>
  );
};

export default PlanBox;
