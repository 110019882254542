import React from 'react';
import { VStack, Box, Text, HStack, Flex } from '@chakra-ui/react';
import theme from 'src/theme';
import { ReactComponent as ArrowLeftBlue } from 'src/assets/mobile/icons/ArrowLeftBlue.svg';
import { useNavigate } from 'react-router-dom';

interface Report {
  createdDate: string;
  id: string;
  reportId: string;
  status: string;
}

interface ReportsListProps {
  reportsData: Report[];
}

const ReportsList: React.FC<ReportsListProps> = ({ reportsData }) => {
  const navigate = useNavigate();

  const getStatusColorScheme = (status: string) => {
    switch (status) {
      case 'DONE':
        return theme.colors.mobile.green;
      case 'FAILED':
        return theme.colors.mobile.red;
      case 'NO_PROFESSOR':
        return theme.colors.mobile.red;
      default:
        return theme.colors.mobile.gray;
    }
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'DONE':
        return 'لیست موفق';
      case 'FAILED':
        return 'ناموفق';
      case 'NO_PROFESSOR':
        return 'ناموفق';
      default:
        return 'درانتظار';
    }
  };

  const sortedReports = [...reportsData].sort(
    (a, b) =>
      new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
  );

  return (
    <VStack
      align="stretch"
      mb="70px"
      backgroundColor={theme.colors.mobile.pbg}
      height="100%"
      width="100%"
    >
      {sortedReports.map((report, index) => (
        <Box
          backgroundColor="#FFFFFF"
          key={report.id}
          width="100%"
          p="16px 12px"
          border="1px solid #EBEBF0"
          borderRadius="6px"
          onClick={() => {
            navigate(`/report/${report.reportId}`, {
              state: { report, index },
            });
          }}
        >
          <HStack justifyContent="space-between" mb="16px">
            <Text
              fontFamily={theme.fonts.family.body}
              fontSize={theme.fonts.size.small}
              fontWeight="400"
              color={theme.colors.texts.secondary}
            >
              {`لیست شماره ${report.id}`}
            </Text>

            <Box
              width="78px"
              height="24px"
              alignContent="center"
              borderRadius="4px"
              backgroundColor={getStatusColorScheme(report.status)}
              color={theme.colors.texts.secondary}
              fontFamily={theme.fonts.family.medium}
              fontSize={theme.fonts.size.lil}
              fontWeight="500"
            >
              {getStatusLabel(report.status)}
            </Box>
          </HStack>
          <HStack justifyContent="space-between">
            <Text
              fontFamily={theme.fonts.family.body}
              fontSize={theme.fonts.size.lil}
              fontWeight="400"
              color={theme.colors.texts.secondary}
            >
              {new Date(report.createdDate).toLocaleString('fa-IR')}
            </Text>
            <Flex
              cursor="pointer"
              alignItems="center"
              onClick={() => {
                // handle view report action
              }}
            >
              <Text
                fontFamily={theme.fonts.family.medium}
                fontSize={theme.fonts.size.small}
                fontWeight="500"
                color={theme.colors.mobile.texts.secondary}
              >
                مشاهده لیست
              </Text>
              <Box marginBottom="3px">
                <ArrowLeftBlue />
              </Box>
            </Flex>
          </HStack>
        </Box>
      ))}
    </VStack>
  );
};

export default ReportsList;
