import React, { ReactNode } from 'react';
import { Box, Container } from '@chakra-ui/react';
import BackgroundImage from '../../assets/images/header_background_image.jpg';
import '../../res_style.css';

interface HeaderContainerProps {
  children: ReactNode;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({ children }) => {
  return (
    <Box
      mb="88px"
      height={{ base: '1250px', md: '100vh' }}
      display="flex"
      flexDirection="column"
      position="relative"
      bgImage={`url(${BackgroundImage})`}
      bgSize="cover"
      bgPosition="center"
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        bottom={0}
        width={{ base: '100%', md: '40%' }} // Full width on mobile
        bg="rgba(255, 255, 255, 0.9)"
        zIndex={1}
      />

      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width={{ base: '100%', md: '60%' }} // Full width on mobile
        bg="rgba(51, 51, 51, 0.9)"
        zIndex={1}
      />
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        width={{ base: '100%', md: '60%' }} // Full width on mobile
        background="linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))"
        zIndex={1}
      />
      <Container
        className="header_container_content"
        maxW={{ base: '100%', md: 'container.lg' }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default HeaderContainer;
