import React from 'react';
import { Box, Text, HStack, Tag, TagLabel, Flex } from '@chakra-ui/react';
import ReportFilter from './ReportFilter';
import { ReactComponent as ReportPendingStateImage } from 'src/assets/mobile/images/ReportPendingStateImage.svg';
import theme from 'src/theme';
import PagesHeader from './PagesHeader';

const PendingReport: React.FC<{
  index: number;
  country: string;
  intrests: string[];
  date: string;
}> = ({ index, country, intrests, date }) => {
  return (
    <Box
      textAlign="center"
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="56px"
      paddingBottom="80px"
    >
      <PagesHeader
        text={`لیست شماره ${index + 1}`}
        showBackButton={true}
        bgc={theme.colors.mobile.pbg}
      />
      <ReportFilter
        index={index}
        country={country}
        intrests={intrests}
        date={date}
      />
      <Box marginX="16px">
        <Box textAlign="center" justifyContent="center">
          <Flex
            width="100%"
            textAlign="center"
            justifyContent="center"
            marginTop="19px"
            marginBottom="8px"
          >
            <ReportPendingStateImage width="150px" />
          </Flex>
          <Text
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={theme.fonts.size.small}
            color={theme.colors.mobile.texts.primary}
            marginBottom="24px"
          >
            لیست شما آماده نشده است لطفا منتظر باشید.
          </Text>
          <Text
            backgroundColor={theme.colors.mobile.secondary}
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={theme.fonts.size.small}
            color={theme.colors.secondary}
            padding="8px 12px"
            borderRadius="8px"
            textAlign="start"
          >
            به محض آماده سازی لیست از طریق ایمیل شما را مطلع خواهیم کرد.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PendingReport;
