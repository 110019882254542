import React from 'react';
import { Box, Text, Flex, Spinner, useBreakpointValue } from '@chakra-ui/react';
import { PlanData } from './PlanBox';
import PlanBox from './PlanBox';
import { ReactComponent as PlanModalCloseIcon } from '../../assets/icons/PlanModalCloseIcon.svg';
import theme from '../../theme';

interface PlanListProps {
  planData: PlanData[];
  loading: boolean;
  onClose: () => void;
  handlePlanSelect: (plan: PlanData) => void;
}

const PlanList: React.FC<PlanListProps> = ({
  planData,
  loading,
  onClose,
  handlePlanSelect,
}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box
      zIndex={0}
      position="relative"
      width="100%"
      pt={isDesktop ? '0px' : '60px'}
    >
      <Box
        position="absolute"
        top="0px"
        left={isDesktop ? '-130px' : '0px'}
        cursor="pointer"
      >
        <PlanModalCloseIcon width="48px" onClick={onClose} />
      </Box>
      <Text
        fontFamily={theme.fonts.family.bold}
        fontWeight="600"
        fontSize={`clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`}
        lineHeight="32px"
        textAlign="center"
        color="white"
        mb="24px"
      >
        طرح فعال یافت نشد؟
      </Text>
      <Text
        fontFamily={theme.fonts.family.body}
        fontWeight="500"
        fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
        lineHeight="28px"
        color="white"
        textAlign="center"
        mb="60px"
      >
        می توانید با خرید اشتراک به لیست نامحدود اساتید دسترسی داشته باشید
      </Text>
      {loading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          gap="80px"
        >
          {planData
            .slice()
            .reverse()
            .map((plan, index) => (
              <PlanBox
                key={index}
                title={plan.title}
                price={plan.price}
                duration={plan.duration}
                features={plan.features}
                onClick={() => handlePlanSelect(plan)}
              />
            ))}
        </Flex>
      )}
    </Box>
  );
};

export default PlanList;
