import axios, { isAxiosError } from 'axios';
import { getAccessToken } from '../services/authService';

const configureAxios = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (isAxiosError(error)) {
        const originalRequest = error.config;

        if (originalRequest && error.response) {
          if (error.response.status === 401 && !originalRequest._retry) {
            // originalRequest._retry = true;
            // try {
            //   await refreshToken();
            //   const newAccessToken = getAccessToken();
            //   if (newAccessToken) {
            //     originalRequest.headers[
            //       'Authorization'
            //     ] = `Bearer ${newAccessToken}`;
            //     return axios(originalRequest);
            //   }
            // } catch (refreshError) {
            //   handleUnauthorizedError(refreshError as AxiosError);
            //   return Promise.reject(refreshError);
            // }
          } else if (error.response.status === 403) {
            console.error(
              'Access Denied: You do not have permission to access this resource.'
            );
          } else if (error.response.status >= 500) {
            console.error(
              'Server Error: Something went wrong on our end. Please try again later.'
            );
          }
        } else if (!error.response) {
          console.error('Network or other error:', error.message);
        }
      } else {
        console.error('Unexpected error:', error);
      }

      return Promise.reject(error);
    }
  );
};

export default configureAxios;
