import React from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import theme from '../../theme';
import StarIcon from '../../assets/icons/star.png';
import ArrowDownIcon from '../../assets/icons/arrow-down.png';
import { Navigate, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

type HeaderContainerRightProps = {
  title1: string;
  title2: string;
  description: string;
  learnMore: string;
};
const MotionBox = motion(Box);

const HeaderContainerRight: React.FC<HeaderContainerRightProps> = ({
  title1,
  title2,
  description,
  learnMore,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box textAlign="right">
        <Flex align="center" marginBottom={{ base: '10px', md: '20px' }}>
          <Image
            src={StarIcon}
            alt="Star Icon"
            height={{ base: '16px', md: '20px' }}
            width={{ base: '16px', md: '20px' }}
            marginLeft={{ base: '5px', md: '10px' }}
            marginBottom="4px"
          />
          <Text
            fontFamily={theme.fonts.family.bold}
            fontSize={{
              base: theme.fonts.size.small,
              md: theme.fonts.size.semismall,
            }}
            color={theme.colors.texts.white}
          >
            {title1}
          </Text>
        </Flex>
        <Text
          fontFamily={theme.fonts.family.bold}
          fontSize={{
            base: theme.fonts.size.ultra,
            md: theme.fonts.size.ultrabig,
          }}
          color={theme.colors.texts.primary}
          marginBottom={{ base: '24px', md: '48px' }}
        >
          {title2}
        </Text>
        <Text
          fontFamily={theme.fonts.family.body}
          fontSize={{ base: theme.fonts.size.medium, md: theme.fonts.size.big }}
          color={theme.colors.texts.white}
          marginBottom={{ base: '10px', md: '20px' }}
        >
          {description}
        </Text>
      </Box>

      <Flex
        align="center"
        cursor="pointer"
        onClick={() => navigate('/moredetails')}
      >
        <MotionBox
          animate={{ x: [0, -5, 0] }} // Move to left by 5px and back
          transition={{
            ease: 'easeInOut',
            duration: 1,
            repeat: Infinity,
            repeatDelay: 2,
          }} // Duration of 1.5s, repeats every 3s
        >
          <Image
            src={ArrowDownIcon}
            alt="Arrow Down Icon"
            height={{ base: '16px', md: '20px' }}
            width={{ base: '16px', md: '20px' }}
            marginLeft={{ base: '5px', md: '10px' }}
            transform="rotate(90deg)"
          />
        </MotionBox>

        <Text
          fontFamily={theme.fonts.family.body}
          fontSize={{
            base: theme.fonts.size.small,
            md: theme.fonts.size.medium,
          }}
          color={theme.colors.texts.third}
        >
          {learnMore}
        </Text>
      </Flex>
    </>
  );
};

export default HeaderContainerRight;
