import React from 'react';
import { Box, VStack, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as ArrowLeftBlue } from 'src/assets/mobile/icons/ArrowLeftBlue.svg';
import theme from 'src/theme';

import ReportModal from 'src/components/mobile/ReportModal';
import ReportFilter from './ReportFilter';
import PagesHeader from './PagesHeader';

interface Professor {
  id: number;
  author: string;
  scholarId: string | null;
  urlPicture: string;
  affiliation: string;
  email: string | null;
  interests: string[];
  authorCitations: number;
  university: string | null;
  country: string;
  feedBack: string;
  universityWorldRank: number;
  universityCountryRank: number;
  interestSimilarityScore: number;
}

interface ReportDataProps {
  index: number;
  country: string;
  intrests: string[];
  date: string;
  professors: Professor[];
}

const ReportData: React.FC<ReportDataProps> = ({
  index,
  country,
  intrests,
  date,
  professors,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProfessor, setSelectedProfessor] =
    React.useState<Professor | null>(null);

  const handleDetailsClick = (prof: Professor) => {
    setSelectedProfessor(prof);
    onOpen();
  };

  return (
    <Box
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="56px"
      paddingBottom="80px"
    >
      <PagesHeader text={`لیست شماره ${index + 1}`} showBackButton={true} />
      <ReportFilter
        index={index}
        country={country}
        intrests={intrests}
        date={date}
      />

      <Box marginX="16px">
        <VStack spacing={4} align="stretch">
          {professors.map((prof, index) => (
            <Flex
              key={index}
              p={4}
              borderWidth={1}
              borderRadius="6px"
              align="center"
              justify="space-between"
              backgroundColor="#ffff"
            >
              <VStack align="flex-start">
                <Text fontWeight="bold">{prof.author}</Text>
                <Text>{prof.affiliation}</Text>
              </VStack>
              <Flex
                cursor="pointer"
                alignSelf="end"
                onClick={() => handleDetailsClick(prof)}
              >
                <Text
                  fontFamily={theme.fonts.family.medium}
                  fontSize={theme.fonts.size.small}
                  fontWeight="500"
                  color={theme.colors.mobile.texts.secondary}
                >
                  جزئیات
                </Text>
                <Box marginBottom="3px">
                  <ArrowLeftBlue />
                </Box>
              </Flex>
            </Flex>
          ))}
        </VStack>
      </Box>

      <ReportModal
        isOpen={isOpen}
        onClose={onClose}
        professor={selectedProfessor}
      />
    </Box>
  );
};

export default ReportData;
