import React from 'react';
import {
  Flex,
  Text,
  HStack,
  Tag,
  TagLabel,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import ReportIntrestsModal from './ReportIntrestsModal';
import { ReactComponent as ArrowDown } from 'src/assets/mobile/icons/ArrowDown.svg';
import { ReactComponent as CalanderIcon } from 'src/assets/mobile/icons/CalanderIcon.svg';

interface ReportFilterProps {
  index: number;
  country: string;
  intrests: string[];
  date: string;
}

const ReportFilter: React.FC<ReportFilterProps> = ({
  index,
  country,
  intrests,
  date,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const extractDayAndMonth = (dateStr: string) => {
    const [yearMonthDay, _dayOfWeek] = dateStr.split(', ');
    const [year, month, day] = yearMonthDay.split(' ');
    return `${day} ${month}`;
  };
  return (
    <>
      <Box marginX="16px">
        <HStack spacing={4} mt={4} mb={4}>
          <Tag
            height="32px"
            minHeight="32px"
            borderRadius="full"
            variant="solid"
            backgroundColor="#EBEBF0"
            border="1px solid #8E8E93"
            padding="10px"
          >
            <CalanderIcon width="16px" />
            <TagLabel marginTop="5px" marginRight="4px" color="#404040">
              {extractDayAndMonth(
                new Intl.DateTimeFormat('fa-IR', {
                  dateStyle: 'full',
                }).format(new Date(date))
              )}
            </TagLabel>
          </Tag>
          <Tag
            height="32px"
            minHeight="32px"
            borderRadius="full"
            variant="solid"
            backgroundColor="#EBEBF0"
            border="1px solid #8E8E93"
            padding="10px"
          >
            <TagLabel marginTop="2px" color="#404040">
              {country}
            </TagLabel>
          </Tag>
          <Tag
            height="32px"
            minHeight="32px"
            borderRadius="full"
            variant="solid"
            backgroundColor="#EBEBF0"
            border="1px solid #8E8E93"
            padding="10px"
            onClick={() => onOpen()}
          >
            <TagLabel marginTop="3px" marginLeft="4px" color="#404040">
              زمینه های مورد علاقه
            </TagLabel>
            <ArrowDown />
          </Tag>

          {/* <Tag
            height="32px"
            minHeight="32px"
            borderRadius="full"
            variant="solid"
            backgroundColor="#EBEBF0"
            border="1px solid #8E8E93"
            padding="10px"
          >
            <TagLabel marginTop="2px" color="#404040">
              RANK
            </TagLabel>
          </Tag> */}
        </HStack>
        <ReportIntrestsModal
          isOpen={isOpen}
          onClose={onClose}
          intrests={intrests}
        />
      </Box>
    </>
  );
};

export default ReportFilter;
