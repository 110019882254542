import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Divider,
  Button,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, InfoIcon } from '@chakra-ui/icons';
import theme from '../../theme';

type SuccessSearchProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  iconType: 'success' | 'warning' | 'error';
};

const iconMap = {
  success: { icon: CheckCircleIcon, color: '#219653' },
  warning: { icon: WarningIcon, color: 'yellow.500' },
  error: { icon: InfoIcon, color: 'red.500' },
};

const SuccessSearch: React.FC<SuccessSearchProps> = ({
  isOpen,
  onClose,
  title,
  description,
  iconType,
}) => {
  const { icon: IconComponent, color } = iconMap[iconType];

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        textAlign="center"
        p={{ base: '16px', md: '32px' }}
        w={{ base: '90%', md: '700px' }}
        minWidth={{ base: '90%', md: '600px' }}
        minHeight={{ base: '300px', md: '500px' }}
      >
        <ModalCloseButton />
        <ModalBody p="0px" alignContent="center">
          <IconComponent
            w={{ base: '24px', md: '32px' }}
            h={{ base: '24px', md: '32px' }}
            color={color}
          />
          <Text
            color={color}
            fontFamily={theme.fonts.family.bold}
            fontSize={{
              base: theme.fonts.size.medium,
              md: `clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`,
            }}
            my={{ base: '16px', md: '32px' }}
          >
            {title}
          </Text>
          <Divider mb={{ base: '16px', md: '24px' }} />
          <Text
            px={{ base: '16px', md: '30px' }}
            fontFamily={theme.fonts.family.medium}
            fontSize={{
              base: theme.fonts.size.medium,
              md: `clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`,
            }}
          >
            {description}
          </Text>
          {iconType === 'success' && (
            <Button
              mt="30px"
              borderRadius="0px"
              backgroundColor={theme.colors.texts.secondary}
              fontFamily={theme.fonts.family.bold}
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              color={theme.colors.texts.white}
              padding="14px 36.5px"
              _hover={{
                backgroundColor: theme.colors.hover,
              }}
              onClick={onClose}
            >
              لیست جستجوهای اخیر
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuccessSearch;
