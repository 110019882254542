import React from 'react';
import {
  Box,
  Button,
  Text,
  Flex,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import QuoteIcon from '../../assets/icons/QuoteIcon.svg';
import theme from '../../theme';
import PlanPrice from '../plans/PlanPrice';

const MotionButton = motion(Button);

const ListPlans: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg="#25282E"
      color="white"
      p="25px 32px"
      borderRadius="10px"
      border="solid 1px #282D45"
      justifyContent="space-between"
      width="1033px"
      m="auto"
      mt="-30px"
    >
      <Image src={QuoteIcon} w="40px" mb="25px" />
      <Box
        color="white"
        borderRadius="md"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          fontFamily={theme.fonts.family.body}
          fontWeight="400"
          fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
          lineHeight="24px"
          color="white"
          py="16px"
        >
          برای دسترسی و مشاهده لیست کامل از اساتید مورد نظر خود اشتراک تهیه
          کنید.
        </Text>
        <MotionButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          bg="white"
          p="14px 29px"
          color="black"
          height="auto"
          fontFamily={theme.fonts.family.body}
          fontWeight="800"
          fontSize={`clamp(${theme.fonts.size.semismall}, 1.5vw, ${theme.fonts.size.medium})`}
          lineHeight="28px"
          boxShadow="0px 10px 30px 0px #381DDB33"
          onClick={onOpen}
        >
          مشاهده طرح ها
        </MotionButton>
        <PlanPrice isOpen={isOpen} onClose={onClose} />
      </Box>
    </Box>
  );
};

export default ListPlans;
