import React, { useState } from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import theme from '../../theme';

const MotionBox = motion(Box);

interface AccordionProps {
  id: number;
  question: string;
  answer: string;
  icon: string;
}

const CustomAccordion: React.FC<AccordionProps> = ({
  id,
  question,
  answer,
  icon,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAccordionChange = (isOpen: boolean) => {
    setIsExpanded(isOpen);
  };

  return (
    <AccordionItem
      borderRadius="md"
      border="1px solid #CACACA"
      mb="32px"
      onChange={() => handleAccordionChange}
    >
      {() => (
        <>
          <h2>
            <AccordionButton
              my={2}
              _hover={{
                bg: 'none',
              }}
              onClick={toggleAccordion}
            >
              <Flex flex="1" textAlign="right" gap="12px" align="center">
                <Image
                  src={icon}
                  width="32px"
                  transform={isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
                  transition="transform 0.3s ease-in-out"
                />
                <Text
                  fontFamily={theme.fonts.family.medium}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
                  color="#333D55"
                >
                  {question}
                </Text>
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel overflow="hidden" pt={0}>
            <MotionBox
              initial={{ maxHeight: 0 }}
              animate={{ maxHeight: isExpanded ? '500px' : '0px' }}
              transition={{ duration: 0.5 }}
            >
              <Box
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.medium}, 1.5vw, ${theme.fonts.size.semimedium})`}
                color="#000000"
              >
                {answer}
              </Box>
            </MotionBox>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default CustomAccordion;
