import { CircularProgress } from '@chakra-ui/react';
import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderProps {
  color1: string;
  color2: string;
  size: number;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); // semi-transparent background
  z-index: 9999; // ensure it sits above other content
`;

const SnurraAnimation = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -403px;
  }
`;

const Wrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const Snurra = styled.svg<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  filter: url(#gegga);
`;

const Halvan = styled.path`
  animation: ${SnurraAnimation} 10s infinite linear;
  stroke-dasharray: 180 800;
  fill: none;
  stroke-width: 23;
  stroke-linecap: round;
`;

const Strecken = styled.circle`
  animation: ${SnurraAnimation} 3s infinite linear;
  stroke-dasharray: 26 54;
  fill: none;
  stroke-width: 23;
  stroke-linecap: round;
`;

const Skugga = styled.svg<{ size: number }>`
  filter: blur(5px);
  opacity: 0.3;
  position: absolute;
  transform: translate(3px, 3px);
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const Loader: React.FC<LoaderProps> = ({ color1, color2, size }) => {
  return (
    <Overlay>
      <CircularProgress color="#2984F2" isIndeterminate />
      {/* <Wrapper size={size}>
        <svg className="gegga" width="0" height="0">
          <defs>
            <filter id="gegga">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="7"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10"
                result="inreGegga"
              />
              <feComposite in="SourceGraphic" in2="inreGegga" operator="atop" />
            </filter>
          </defs>
        </svg>
        <Snurra size={size} viewBox="0 0 200 200">
          <defs>
            <linearGradient id="linjärGradient">
              <stop stopColor={color1} offset="0" />
              <stop stopColor={color2} offset="1" />
            </linearGradient>
            <linearGradient
              y2="160"
              x2="160"
              y1="40"
              x1="40"
              gradientUnits="userSpaceOnUse"
              id="gradient"
              xlinkHref="#linjärGradient"
            />
          </defs>
          <Halvan
            d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
            stroke="url(#gradient)"
          />
          <Strecken cx="100" cy="100" r="64" stroke="url(#gradient)" />
        </Snurra>
        <Skugga size={size} viewBox="0 0 200 200">
          <Halvan
            d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
            stroke="url(#gradient)"
          />
          <Strecken cx="100" cy="100" r="64" stroke="url(#gradient)" />
        </Skugga>
      </Wrapper> */}
    </Overlay>
  );
};

export default Loader;
