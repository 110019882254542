import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  Button,
  Box,
  Input,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider,
  Flex,
  Text,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import theme from 'src/theme';
import { ReactComponent as TriangleDownIcon } from 'src/assets/mobile/icons/TriangleDownIcon.svg';
import { ReactComponent as CloseModal } from 'src/assets/icons/CloseModal.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/Search.svg';
import CustomRadiobox from 'src/components/CustomRadiobox';

interface Option {
  value: string;
  label: string;
}

interface DropdownCountryCheckboxesProps {
  fetchOptions: () => Promise<Option[]>;
  placeholder: string;
  onConfirm: (selectedValue: string) => void;
}

const DropdownCountryCheckboxes: React.FC<DropdownCountryCheckboxesProps> = ({
  fetchOptions,
  placeholder,
  onConfirm,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const hasMounted = useRef(false);

  // Fetch data from parent
  useEffect(() => {
    console.log('Effect: isMenuOpen changed /// Fetch data from parent');
    if (isMenuOpen && options.length === 0) {
      fetchOptions()
        .then((data) => {
          setOptions(data);
          setFilteredOptions(data.slice(0, 6)); // Show the first 6 data items initially
        })
        .catch((error) => console.error('Error loading options:', error));
    }
  }, [isMenuOpen]);

  // Check if the is modal opened or not
  useEffect(() => {
    console.log(
      'Effect: isOpen changed /// Check if the is modal opened or not'
    );
    console.log(' isOpen  ', isOpen);
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // isFocused Changed
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    }
    if (searchInputRef.current && !isFocused) {
      searchInputRef.current.focus();
    }
  }, [isFocused]);

  const handleCheckboxChange = (value: string) => {
    setSelectedValue(value === selectedValue ? '' : value);
  };

  const handleConfirm = useCallback(() => {
    onConfirm(selectedValue);
    setSearchQuery('');
    setIsMenuOpen(false);
    onClose();
  }, [onConfirm, selectedValue, onClose]);

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    onOpen();
  };

  const handleModalClose = () => {
    setIsMenuOpen(false);
    setSearchQuery('');
    onClose();
  };

  const handleInputFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleInputBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocused(false);
    }
  };

  const fetchCountrys = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const startMatches = options.filter((option) =>
      option.label.toLowerCase().startsWith(lowerCaseQuery)
    );
    const containsMatches = options.filter(
      (option) =>
        !option.label.toLowerCase().startsWith(lowerCaseQuery) &&
        option.label.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredOptions([...startMatches, ...containsMatches].slice(0, 6));
  };

  useEffect(() => {
    console.log('Effect: searchQuery changed');
    if (searchQuery.length > 0) {
      fetchCountrys(searchQuery);
    } else {
      setFilteredOptions(options.slice(0, 6));
    }
  }, [searchQuery]);

  return (
    <Box marginBottom="20px">
      <Menu isOpen={isMenuOpen} onOpen={handleMenuOpen}>
        <MenuButton
          as={Button}
          rightIcon={<TriangleDownIcon width="16px" height="16px" />}
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.small}
          color={theme.colors.texts.secondary}
          fontWeight="regular"
          height="auto"
          minHeight="auto"
          width="100%"
          border="1px solid #ECECEC"
          borderRadius="8px"
          backgroundColor="white"
          textAlign="right"
          padding={selectedValue ? '6px 12px' : '16px 12px'}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          className="dropdown_button"
        >
          <Box
            as="span"
            display="block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            className="ltr-direction"
            color={selectedValue ? '#A3A3A3' : '#57575A'}
          >
            {placeholder}
          </Box>
          <Box
            as="span"
            display="block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            className="ltr-direction"
          >
            {selectedValue && (
              <Text
                as="span"
                fontSize={theme.fonts.size.small}
                m="6px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                lineHeight="25px"
                className="ltr-direction"
              >
                {selectedValue}
              </Text>
            )}
          </Box>
        </MenuButton>
        <Modal
          isOpen={isOpen}
          onClose={handleModalClose}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent
            w="100%"
            minHeight="600px"
            position="fixed"
            bottom="0"
            onClick={handleInputFocus}
            minWidth="100%"
          >
            <ModalHeader p="16px 24px">
              <Flex justifyContent="space-between">
                <Text
                  fontFamily={theme.fonts.family.body}
                  fontSize={theme.fonts.size.semismall}
                  fontWeight="700"
                >
                  {placeholder + ' '}
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="500"
                  >
                    (یک مورد به انگلیسی وارد شود)
                  </Text>
                </Text>
                <Flex
                  p="12px"
                  margin="-12px"
                  alignItems="start"
                  cursor="pointer"
                  onClick={handleModalClose}
                >
                  <CloseModal />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody>
              <InputGroup>
                {!isFocused && (
                  <InputLeftElement pointerEvents="none">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box mt="7px" ml="20px">
                        <SearchIcon width="16px" height="16px" />
                      </Box>
                    </motion.div>
                  </InputLeftElement>
                )}
                <Input
                  ref={searchInputRef}
                  lineHeight="normal"
                  dir="ltr"
                  placeholder={!isFocused ? 'جستجو' : `جستجوی ${placeholder}`}
                  _placeholder={{
                    alignContent: 'center',
                    color: !isFocused
                      ? theme.colors.texts.secondary
                      : '#8C8C8C',
                    fontFamily: theme.fonts.family.body,
                    fontWeight: '400',
                    fontSize: '12px',
                    transition: 'color 0.2s ease',
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  p="12px 20px"
                  pl={!isFocused ? '44px' : '16px'}
                  transition="padding-left 0.2s ease"
                  marginBottom="10px"
                  backgroundColor="#F8F9FB"
                  border="none"
                  h="48px"
                  textAlign="left"
                />
              </InputGroup>
              {filteredOptions.length === 0 && searchQuery && (
                <Box p={2} textAlign="center">
                  No results found
                </Box>
              )}
              {filteredOptions.map((option) => (
                <React.Fragment key={option.value}>
                  <Box py="0px" px="12px">
                    <CustomRadiobox
                      isChecked={selectedValue === option.value}
                      label={option.label}
                      onChange={() => handleCheckboxChange(option.value)}
                    />
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button
                width="100%"
                onClick={handleConfirm}
                borderRadius="8px"
                backgroundColor={theme.colors.texts.secondary}
                fontFamily={theme.fonts.family.bold}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color={theme.colors.texts.white}
                _hover={{
                  backgroundColor: theme.colors.hover,
                }}
              >
                تایید
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Menu>
    </Box>
  );
};

export default DropdownCountryCheckboxes;
