import React from 'react';
import {
  VStack,
  Text,
  FormLabel,
  Input,
  Button,
  Divider,
} from '@chakra-ui/react';

import { ReactComponent as GoogleIcon } from '../../assets/icons/Google.svg';
import theme from '../../theme';
import { redirectToGoogleAuth } from '../../services/GoogleAuth';

interface AuthStepOneProps {
  email: string;
  setEmail: (email: string) => void;
  handleEmailSubmit: () => void;
}

const AuthStepOne: React.FC<AuthStepOneProps> = ({
  email,
  setEmail,
  handleEmailSubmit,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEmailSubmit();
    }
  };

  return (
    <VStack alignItems="flex-start" spacing={0}>
      <Text
        fontFamily={theme.fonts.family.body}
        fontWeight="medium"
        fontSize={`clamp(${theme.fonts.size.medium}, 2vw, ${theme.fonts.size.semimedium})`}
        color={theme.colors.texts.semiprimary}
        marginBottom="30px"
      >
        ورود یا ثبت‌نام
      </Text>

      <FormLabel
        htmlFor="email-input"
        fontFamily={theme.fonts.family.body}
        fontWeight="medium"
        fontSize={`clamp(${theme.fonts.size.lil}, 2vw, ${theme.fonts.size.small})`}
        color={theme.colors.texts.semiprimary}
        cursor="pointer"
        mb="4px"
      >
        ایمیل
      </FormLabel>
      <Input
        id="email-input"
        placeholder="Name@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        mb="30px"
        p="8px"
        h="40px"
        lineHeight="50px"
        borderRadius="8px"
        backgroundColor={theme.colors.forminput}
        textAlign="left" // Ensures text direction is LTR
        dir="ltr" // Ensures text direction is LTR
        onKeyDown={handleKeyDown} // Handle Enter key press
        _placeholder={{
          fontWeight: '400',
          color: '#829AB1',
          lineHeight: '40px', // Match the height of the input
          fontSize: `${theme.fonts.size.lil}`,
        }}
      />

      <Button
        onClick={handleEmailSubmit}
        width="100%"
        backgroundColor={theme.colors.secondary}
        borderRadius="8px"
        padding="10px 0px"
        h="48px"
        mb="16px"
        fontFamily={theme.fonts.family.body}
        fontWeight="medium"
        fontSize={`clamp(${theme.fonts.size.lil}, 2vw, ${theme.fonts.size.small})`}
        color={theme.colors.texts.white}
        _hover={{
          backgroundColor: `${theme.colors.hover}`,
          color: `${theme.colors.texts.white}`,
        }}
      >
        ادامه
      </Button>
      <Divider mb="16px" borderColor="#BCCCDC" borderWidth="1px" />
      <Button
        variant="outline"
        width="100%"
        height="auto"
        borderRadius="8px"
        padding="12px 0px"
        fontFamily={theme.fonts.family.body}
        fontWeight="medium"
        fontSize={`clamp(${theme.fonts.size.vlil}, 2vw, ${theme.fonts.size.lil})`}
        color="#344054"
        border="solid 1px #D0D5DD"
        boxShadow="0px 1px 2px 0px #1018280D"
        onClick={redirectToGoogleAuth} // Call redirectToGoogleAuth on button click
      >
        <Text mt="6px" ml="12px">
          Sign in with Google
        </Text>
        <GoogleIcon />
      </Button>
    </VStack>
  );
};

export default AuthStepOne;
