import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Link, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import theme from '../../theme';
import { AuthContext } from '../../context/AuthContext';
import AuthModal from '../auth/AuthModal';
import UserDropDown from '../auth/UserDropDown';

const AuthButtonGroup: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setPreviousPath(location.pathname); // Save the current path
      window.history.pushState(null, '', '/login'); // Update the URL path to /plans
    }
  }, [isOpen, navigate]);

  if (!authContext) {
    return null;
  }

  const { isAuthenticated } = authContext;

  const handleClose = () => {
    onClose();
    navigate(-1);
  };

  return (
    <>
      {isAuthenticated ? (
        <UserDropDown />
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={onOpen}
          cursor="pointer"
        >
          <Link
            paddingY="14px"
            color={{
              base: theme.colors.texts.white,
              md: theme.colors.texts.secondary,
            }}
            fontFamily={theme.fonts.family.body}
            cursor="pointer"
            fontSize={theme.fonts.size.small}
            fontWeight="semibold"
            paddingLeft="40px"
            _hover={{
              textDecoration: 'none',
              color: theme.colors.texts.textHover,
            }}
          >
            ورود
          </Link>

          <Button
            fontFamily={theme.fonts.family.bold}
            variant="outline"
            color={{
              base: theme.colors.texts.white,
              md: theme.colors.texts.secondary,
            }}
            fontSize={theme.fonts.size.small}
            borderColor={{
              base: theme.colors.texts.white,
              md: theme.colors.texts.secondary,
            }}
            paddingX="16px"
            paddingY="14px"
            borderRadius="none"
            _hover={{
              backgroundColor: theme.colors.texts.secondary,
              color: theme.colors.texts.white,
            }}
          >
            ثبت نام
          </Button>
          <AuthModal isOpen={isOpen} onClose={handleClose} />
        </Box>
      )}
    </>
  );
};

export default AuthButtonGroup;
