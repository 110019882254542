import React, { useState, useEffect, useContext } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  Button,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider,
  Flex,
  Text,
} from '@chakra-ui/react';

import theme from 'src/theme';
import { ReactComponent as TriangleDownIcon } from 'src/assets/mobile/icons/TriangleDownIcon.svg';

import { AppContext } from 'src/context/AppContext';
import { ReactComponent as CloseModal } from 'src/assets/icons/CloseModal.svg';
import CustomRadiobox from 'src/components/CustomRadiobox';

interface Option {
  value: string;
  label: string;
}

interface DropdownRankCheckboxProps {
  fetchOptions: () => Promise<Option[]>;
  placeholder: string;
  onConfirm: (selectedValue: string) => void;
}

const DropdownRankCheckbox: React.FC<DropdownRankCheckboxProps> = ({
  fetchOptions,
  placeholder,
  onConfirm,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const appContext = useContext(AppContext);
  const UniversityRankSelected = appContext
    ? appContext.UniversityRankSelected
    : null;

  useEffect(() => {
    if (isMenuOpen && options.length === 0) {
      fetchOptions()
        .then((data) => {
          setOptions(data);
        })
        .catch((error) => console.error('Error loading options:', error));
    }
  }, [isMenuOpen, fetchOptions]);

  const handleCheckboxChange = (value: string) => {
    setSelectedValue((prev) => (prev === value ? null : value));
  };

  const handleConfirm = () => {
    if (selectedValue) {
      onConfirm(selectedValue);
    } else {
      onConfirm('');
    }
    setIsMenuOpen(false);
    onClose();
  };

  const handleMenuOpen = () => {
    setSelectedValue(UniversityRankSelected); // Load current selection when opening the modal
    setIsMenuOpen(true);
    onOpen();
  };

  const handleModalClose = () => {
    setIsMenuOpen(false);
    onClose();
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    onClose();
  };

  return (
    <Box marginBottom="20px">
      <Menu isOpen={isMenuOpen} onOpen={handleMenuOpen}>
        <MenuButton
          as={Button}
          rightIcon={<TriangleDownIcon width="16px" height="16px" />}
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.small}
          color={theme.colors.texts.secondary}
          fontWeight="regular"
          height="auto"
          minHeight="auto"
          width="100%"
          border="1px solid #ECECEC"
          borderRadius="8px"
          backgroundColor="white"
          textAlign="right"
          padding={selectedValue ? '6px 12px' : '16px 12px'}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          className="dropdown_button"
        >
          <Box
            as="span"
            display="block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            className="ltr-direction"
            color={selectedValue ? '#A3A3A3' : '#57575A'}
          >
            {placeholder}
          </Box>
          <Box
            as="span"
            display="block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            className="ltr-direction"
          >
            {selectedValue && (
              <Text
                as="span"
                fontSize={theme.fonts.size.small}
                m="6px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                lineHeight="25px"
                className="ltr-direction"
              >
                {selectedValue}
              </Text>
            )}
          </Box>
        </MenuButton>
        <Modal
          isOpen={isOpen}
          onClose={handleModalClose}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent minHeight="auto" minWidth="100%">
            <ModalHeader p="16px 24px">
              <Flex justifyContent="space-between">
                <Text fontFamily={theme.fonts.family.body}>
                  {placeholder + ' '}
                  <Text
                    as="span"
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    color={theme.colors.texts.secondary}
                  >
                    (یک مورد انتخاب شود)
                  </Text>
                </Text>
                <Flex
                  p="12px"
                  margin="-12px"
                  alignItems="start"
                  cursor="pointer"
                  onClick={handleModalClose}
                >
                  <CloseModal />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody pt="16px">
              {options.map((option: Option, index) =>
                index === 0 || index === 1 ? (
                  <React.Fragment key={option.value}>
                    <MenuItem py="0px" closeOnSelect={false}>
                      <CustomRadiobox
                        isChecked={selectedValue === option.value}
                        label={option.label}
                        onChange={() => handleCheckboxChange(option.value)}
                      />
                    </MenuItem>
                    <Divider />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={option.value}>
                    <MenuItem
                      py="0px"
                      closeOnSelect={false}
                      _focus={{ backgroundColor: 'none' }}
                    >
                      <CustomRadiobox
                        isChecked={selectedValue === option.value}
                        label={option.label}
                        onChange={() => handleCheckboxChange(option.value)}
                        disabled={true}
                      />
                    </MenuItem>
                    <Divider />
                  </React.Fragment>
                )
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                width="100%"
                onClick={handleConfirm}
                borderRadius="8px"
                backgroundColor={theme.colors.texts.secondary}
                fontFamily={theme.fonts.family.bold}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color={theme.colors.texts.white}
                _hover={{
                  backgroundColor: theme.colors.hover,
                }}
              >
                تایید
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Menu>
    </Box>
  );
};

export default DropdownRankCheckbox;
