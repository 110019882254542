import React, { useState, useEffect } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import CheckIcon from '../../assets/icons/CheckIcon.svg';

interface FilterCheckboxProps {
  label: string;
  onStatusChange?: (status: boolean) => void;
  initialChecked?: boolean;
}

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  label,
  onStatusChange,
  initialChecked = false,
}) => {
  const [isChecked, setIsChecked] = useState(initialChecked);
  const [isDisabled, setIsDisabled] = useState(initialChecked);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(isChecked);
    }
  }, [isChecked, onStatusChange]);

  const handleCheckboxChange = () => {
    if (!isDisabled) {
      setIsChecked(!isChecked);
    }
  };

  return (
    <Box
      as={motion.div}
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="40px"
      borderRadius="8px"
      background={isChecked ? 'rgba(41, 132, 242, 0.2)' : 'gray.200'}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={initialChecked ? undefined : handleCheckboxChange}
      whileTap={isDisabled ? {} : { scale: 0.95 }}
      transition={{ duration: '0.2' }}
      overflow="hidden"
      px="16px"
      position="relative"
    >
      <motion.div
        initial={false}
        animate={{ paddingLeft: isChecked ? '18px' : '0px' }}
        transition={{ duration: 0.2 }}
      >
        <Text>{label}</Text>
      </motion.div>
      <AnimatePresence initial={false}>
        {isChecked && (
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            animate={{ left: '8px', opacity: 1 }}
            exit={{ x: '50%', opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ position: 'absolute' }}
          >
            <Image src={CheckIcon} alt="Checked" width="18px" height="18px" />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default FilterCheckbox;
