import React from 'react';
import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const LoginError: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
    >
      <Box textAlign="center" p={8} bg="white" rounded="md" shadow="md">
        <Heading as="h2" size="xl" mb={4} color="red.500">
          Login Error
        </Heading>
        <Text fontSize="lg" mb={4}>
          Oops! Something went wrong during the login process.
        </Text>
        <Button colorScheme="teal" onClick={handleGoBack}>
          Go Back to Home
        </Button>
      </Box>
    </Flex>
  );
};

export default LoginError;
