import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from './constants';
import showToast from './toastService';

// Function to send OTP
export const sendOtp = async (email: string): Promise<void> => {
  const response = await axios.post(`${API_BASE_URL}/login/send-otp`, {
    email,
  });
  if (response.data.code !== 0) {
    throw new Error(response.data.message);
  }
};

// Function to verify OTP and store tokens
export const verifyOtp = async (
  email: string,
  otpCode: string
): Promise<void> => {
  const response = await axios.post(`${API_BASE_URL}/login/verify-otp`, {
    email,
    otpCode,
  });
  if (response.data.code !== 0) {
    throw new Error(response.data.message);
  }
  localStorage.setItem('accessToken', response.data.body.accessToken);
  if (response.data.body.refreshToken) {
    localStorage.setItem('refreshToken', response.data.body.refreshToken);
  }
  return response.data.body;
};

// Function to refresh access token
export const refreshToken = async (): Promise<void> => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  const response = await axios.post(`${API_BASE_URL}/login/refresh-token`, {
    token: refreshToken,
  });
  console.log(response);

  if (response.data.code !== 0) {
    console.log('response.data.code --> ', response.data.code);

    throw new Error(response.data.message);
  }

  localStorage.setItem('accessToken', response.data.body.accessToken);
  if (response.data.body.refreshToken) {
    localStorage.setItem('refreshToken', response.data.body.refreshToken);
  }
};

// Function to get access token from local storage
export const getAccessToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

// Function to log out the user
export const logout = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
};

export const handleUnauthorizedError = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    logout();
    window.location.reload();
  } else {
    showToast({
      description: 'ورود شما به اتمام رسیده، دوباره وارد شوید',
      status: 'error',
    });
  }
  throw error;
};
