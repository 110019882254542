import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import theme from '../../theme';
import { AppContext } from '../../context/AppContext';
import HeaderContainerRight from './HeaderContainerRight';
import HeaderSearchBox from './HeaderSearchBox';

const HeaderContent: React.FC = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    return null;
  }

  const { headerContent } = appContext;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <Box
        width={{ base: '100%', md: '50%' }}
        paddingLeft={{ base: '16px', md: '53px' }}
        textAlign={{ base: 'center', md: 'right' }}
        mb={{ base: '40px', md: '0' }}
      >
        <HeaderContainerRight
          title1={headerContent.title1}
          title2={headerContent.title2}
          description={headerContent.description}
          learnMore={headerContent.learnMore}
        />
      </Box>
      <Box
        width={{ base: '100%', md: '50%' }}
        display="flex"
        justifyContent={{ base: 'center', md: 'left' }}
      >
        <HeaderSearchBox searchPlaceholder={headerContent.searchPlaceholder} />
      </Box>
    </Box>
  );
};

export default HeaderContent;
