import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';

export const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    console.error('AuthContext is undefined in PrivateRoute');
    return null;
  }

  const { isAuthenticated, isLoading } = authContext;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
};
