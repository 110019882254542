import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Link,
  Box,
  HStack,
  IconButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';

import { ReactComponent as CloseModalIcon } from 'src/assets/mobile/icons/CloseModal.svg';

import theme from 'src/theme';
import { useNavigate } from 'react-router-dom';
import { logout as authLogout } from '../../services/authService';
import { AuthContext } from 'src/context/AuthContext';

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }
  const { logout } = authContext;
  const handleNoClick = () => {
    onClose();
  };
  const handleYesClick = () => {
    authLogout();
    logout();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        borderTopRadius="lg"
        position="relative"
        width="100%"
        minW="100%"
      >
        <Box position="absolute" top="24px" left="24px" onClick={onClose}>
          <CloseModalIcon />
        </Box>
        <ModalHeader
          textAlign="start"
          height="72px"
          minHeight="72px"
          borderBottom="solid 1px #EBEBF0"
        >
          خروج
        </ModalHeader>
        <ModalBody width="100%">
          آیا قصد خارج شدن از حساب کاربری خود در اپلای‌طوری را دارید؟
        </ModalBody>
        <Flex width="100%" gap="10px" backgroundColor="#FFFFFF" padding="16px">
          <Button
            onClick={handleNoClick}
            width="100%"
            backgroundColor={theme.colors.secondary}
            borderRadius="8px"
            h="48px"
            color={theme.colors.texts.white}
            _hover={{
              backgroundColor: theme.colors.secondary,
              color: theme.colors.texts.white,
            }}
          >
            خیر
          </Button>
          <Button
            onClick={handleYesClick}
            width="100%"
            backgroundColor="#EBEBF0"
            borderRadius="8px"
            h="48px"
            color={theme.colors.secondary}
            _hover={{
              backgroundColor: theme.colors.secondary,
              color: theme.colors.texts.white,
            }}
          >
            تایید
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default LogoutModal;
