import axios from 'axios';
import { API_BASE_URL } from './constants';

export const initiatePayment = async (
  reportTypeId: number,
  voucherCode?: string
) => {
  try {
    const params: any = { reportTypeId };
    if (voucherCode) {
      params.voucherCode = voucherCode;
    }

    const response = await axios.get(`${API_BASE_URL}/payment`, { params });
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const oneStepPayment = async (
  reportTypeId: number,
  voucherCode?: string
) => {
  try {
    const params: any = { reportTypeId };
    if (voucherCode) {
      params.voucherCode = voucherCode;
    }

    const response = await axios.get(`${API_BASE_URL}/payment/one-step`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const verifyPayment = async (authority: string, status: string) => {
  try {
    // Create a new FormData object
    const formData = new FormData();
    formData.append('Authority', authority);
    formData.append('Status', status);

    // Configure Axios to send FormData
    const response = await axios.post(`${API_BASE_URL}/payment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    // Handle AxiosError to access response and request properties
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        console.error('Server Error:', error.response.data);
        throw new Error('Server Error. Please try again later.');
      } else if (error.request) {
        // Request was made but no response received
        console.error('Request Error:', error.request);
        throw new Error(
          'Request Error. Please check your internet connection.'
        );
      } else {
        // Something happened in setting up the request
        console.error('Error:', error.message);
        throw new Error('Error. Please try again later.');
      }
    } else {
      // Handle other types of errors
      console.error('Unknown Error:', error);
      throw new Error('Unknown Error occurred.');
    }
  }
};

export const getAllTransactions = async (
  page = 0,
  size = 100,
  fromDate = '',
  toDate = ''
) => {
  try {
    const params = { page, size };
    const body = {
      page,
      size,
      fromDate,
      toDate,
    };
    const response = await axios.post(
      `${API_BASE_URL}/payment/transaction/list`,
      body
    );

    return response.data.body.content;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};
