import { AxiosError } from 'axios';
import { getPlanData } from '../../services/priceService';
import { handleUnauthorizedError } from '../../services/authService';

export const fetchPlanData = async (
  setLoading: (loading: boolean) => void,
  setPlanData: (data: any) => void
) => {
  setLoading(true);
  try {
    const data = await getPlanData();
    const formattedData = data.map((plan: any) => ({
      title: plan.id === 2 ? 'اشتراک بتا' : 'اشتراک رایگان',
      price: `${plan.price.toLocaleString('fa-IR')}`,
      duration: 'ماهانه',
      features: [
        `امکان جستجوی اساتید`,
        // `به تعداد ${plan.reportPerPayment} بار`,
        plan.id === 2
          ? 'نمایش کامل لیست اساتید '
          : 'نمایش 5 استاد از لیست اساتید ',
        plan.id === 2 ? 'یک هفته' : 'یک هفته',
      ],
      planID: plan.id,
    }));
    setPlanData(formattedData);
  } catch (error) {
    console.error('Error fetching plan data:', error);
    handleUnauthorizedError(error as AxiosError);
  } finally {
    setLoading(false);
  }
};
