import React, { useState, useContext } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  Divider,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import moment from 'jalali-moment';
import theme from '../../theme';
import BackArrowIcon from '../../assets/icons/BackArrowIcon.svg';
import { PlanData } from './PlanBox';
import { AuthContext } from '../../context/AuthContext';
import { validateVoucher } from '../../services/voucherService';

interface InvoiceProps {
  selectedPlan: PlanData | null;
  onBack: () => void;
  handlePayment: (
    selectedPlan: any,
    voucherCode?: string,
    discountPercentage?: number
  ) => void;
}

const Invoice: React.FC<InvoiceProps> = ({
  selectedPlan,
  onBack,
  handlePayment,
}) => {
  const [voucherCode, setVoucherCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [voucherStatus, setVoucherStatus] = useState<string | null>(null);
  const currentDate = moment().locale('fa').format('YYYY-MM-DD, HH:mm:ss');
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }

  const { user } = authContext;
  const userIdentifier = user ? user : '';

  const handleVoucherInput = async () => {
    const parsePriceString = (priceString: string): number => {
      const cleanedPriceString = priceString
        .replace(/[^\d۰-۹]/g, '')
        .replace(/[۰٠]/g, '0')
        .replace(/[۱١]/g, '1')
        .replace(/[۲٢]/g, '2')
        .replace(/[۳٣]/g, '3')
        .replace(/[۴٤]/g, '4')
        .replace(/[۵٥]/g, '5')
        .replace(/[۶٦]/g, '6')
        .replace(/[۷٧]/g, '7')
        .replace(/[۸٨]/g, '8')
        .replace(/[۹٩]/g, '9');

      const priceWithoutCommas = cleanedPriceString.replace(/,/g, '');
      return parseFloat(priceWithoutCommas);
    };
    if (!selectedPlan) return;

    try {
      console.log('selectedPlan.price in desktop');
      console.log(selectedPlan.price);

      const price = parsePriceString(selectedPlan.price);

      const discountAmount = await validateVoucher(
        voucherCode,
        userIdentifier,
        price
      );
      const discountPercent = (discountAmount / price) * 100;
      console.log('discountAmount-->', discountAmount);
      console.log('discountPercent-->', discountPercent);

      setDiscountPercentage(discountPercent);
      setVoucherStatus('success');
    } catch (error) {
      setDiscountPercentage(0);
      setVoucherStatus('error');
    }
  };

  const onPaymentClick = () => {
    handlePayment(
      selectedPlan,
      discountPercentage > 0 ? voucherCode : undefined,
      discountPercentage
    );
  };

  return (
    selectedPlan && (
      <Box
        zIndex={0}
        width={['90vw', '80vw', '661px']}
        py={['80px', '80px', '50px']}
        position={isDesktop ? 'absolute' : 'relative'}
      >
        <Image
          src={BackArrowIcon}
          width="48px"
          onClick={onBack}
          position="absolute"
          top="0px"
          left={['0px', '0px', '-130px']}
          cursor="pointer"
        />
        <Text
          position="absolute"
          top="0px"
          right="-130px"
          fontFamily={theme.fonts.family.body}
          fontWeight="500"
          fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
          lineHeight="28px"
          color="white"
        >
          فاکتور
        </Text>
        <Box
          bg="white"
          p={['16px', '24px', '32px']}
          borderRadius="16px"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15)"
        >
          <Button
            bg="#ffff"
            color="#404040"
            borderRadius="12px"
            w="100%"
            h="auto"
            paddingY="18px"
            marginBottom="32px"
            fontFamily={theme.fonts.family.body}
            fontWeight="400"
            fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
            border=" 1px solid #000000"
            _hover={{ backgroundColor: '#ffff' }}
            cursor="default"
          >
            جزئیات فاکتور
          </Button>
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="space-between"
            gap="80px"
          >
            <Text
              fontFamily={theme.fonts.family.bold}
              fontWeight="500"
              fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
              color="#333D55"
              mb="8px"
            >
              {selectedPlan.title} - یک هفته
            </Text>
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="500"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              color="#333D55"
              dir="ltr"
            >
              {currentDate}
            </Text>
          </Flex>
          <Text
            fontFamily={theme.fonts.family.bold}
            fontWeight="700"
            fontSize={`clamp(${theme.fonts.size.big}, 1.5vw, ${theme.fonts.size.ultra})`}
            color="#333D55"
            mb="24px"
          >
            {selectedPlan.price}
            <Text
              as="span"
              fontFamily={theme.fonts.family.body}
              fontWeight="300"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
            >
              ریال
            </Text>
          </Text>
          <Flex justifyContent="space-between" align="center" mb="5px">
            <Text
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              color="#0C0B0B"
            >
              در صورتی که کد تخفیف دارید، کد را وارد کنید.
            </Text>
            <Flex gap="12px">
              <Input
                placeholder="کد تخفیف"
                borderRadius="8px"
                padding="10px 12px"
                background="#F2F2F2"
                height="40px"
                _placeholder={{
                  color: '#828282',
                  fontFamily: `${theme.fonts.family.body}`,
                  fontSize: `clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`,
                  lineHeight: '1.5',
                }}
                display="flex"
                alignItems="center"
                flex="1"
                border="none"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
              />
              <Button
                bg="#404040"
                borderRadius="8px"
                paddingY="18px"
                color="white"
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                _hover={{ backgroundColor: '#333' }}
                onClick={handleVoucherInput}
              >
                اعمال
              </Button>
            </Flex>
          </Flex>
          {voucherStatus && (
            <Text
              color={voucherStatus === 'success' ? 'green' : 'red'}
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
            >
              {voucherStatus === 'success'
                ? discountPercentage === 100
                  ? 'تخفیف اشتراک بتا فعال شد '
                  : 'کد تخفیف اعمال شد.'
                : 'کد تخفیف نادرست است.'}
            </Text>
          )}
          <Divider my="24px" />
          <Box>
            <Flex
              justifyContent="space-between"
              mb="24px"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              color="#0C0B0B"
            >
              <Text>درصد تخفیف:</Text>
              <Text>
                {discountPercentage
                  ? `${discountPercentage.toFixed(2)}%`
                  : '۰ درصد'}
              </Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              mb="24px"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              color="#0C0B0B"
            >
              <Text>شماره پیگیری:</Text>
              <Text>۱۳۷۲۹۶۹۰۰۷۶</Text>
            </Flex>
            <Flex
              justifyContent="space-between"
              mb="24px"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              color="#0C0B0B"
            >
              <Text>بابت:</Text>
              <Text>اشتراک بتا</Text>
            </Flex>
            <Button
              bg="#404040"
              borderRadius="73px"
              w="100%"
              h="48px"
              paddingY="18px"
              color="white"
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
              _hover={{ backgroundColor: '#333' }}
              onClick={onPaymentClick}
            >
              پرداخت
            </Button>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Invoice;
