import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Link,
  Box,
  HStack,
  IconButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import theme from 'src/theme';

interface ReportIntrestsModalProps {
  isOpen: boolean;
  onClose: () => void;
  intrests: string[] | null;
}

const ReportIntrestsModal: React.FC<ReportIntrestsModalProps> = ({
  isOpen,
  onClose,
  intrests,
}) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const handleLike = () => {
    setLiked(!liked);
    if (disliked) setDisliked(false); // Ensure only one state is active at a time
  };

  const handleDislike = () => {
    setDisliked(!disliked);
    if (liked) setLiked(false); // Ensure only one state is active at a time
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent borderTopRadius="lg" position="relative">
        <ModalHeader
          textAlign="start"
          height="72px"
          minHeight="72px"
          borderBottom="solid 1px #EBEBF0"
        >
          زمینه‌های مورد علاقه
        </ModalHeader>
        <ModalBody>
          {intrests?.map((intrest) => (
            <VStack
              align="flex-start"
              width="100%"
              marginTop="8px"
              marginBottom="16px"
            >
              <Flex
                width="100%"
                padding="8px 16px"
                borderBottom="solid 1px #EBEBF0"
                justifyContent="space-between"
              >
                <Text textAlign="start">{intrest}</Text>
              </Flex>
            </VStack>
          ))}
        </ModalBody>

        <Flex
          width="100%"
          borderRadius="8px"
          justifyContent="center"
          cursor="pointer"
          backgroundColor="#FFFFFF"
          padding="16px"
          boxShadow="0px 1px 0px 0px #E6E6E6 inset"
        >
          <Button
            onClick={onClose}
            width="100%"
            backgroundColor={theme.colors.secondary}
            borderRadius="8px"
            h="48px"
            color={theme.colors.texts.white}
          >
            تایید
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ReportIntrestsModal;
