import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
} from '@chakra-ui/react';
import theme from '../../theme';
import { ReactComponent as FolderIcon } from '../../assets/icons/FolderIcon.svg';
import { useNavigate } from 'react-router-dom';

interface Report {
  createdDate: string;
  id: string;
  reportId: string;
  country: string;
  interestList: string[];
  reportTypeId: number;
  status: string;
}

interface LastReportsTableProps {
  data: Report[];
}

const LastReportsTable: React.FC<LastReportsTableProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleRowClick = (report: Report) => {
    navigate(`/report/${report.reportId}`);
  };

  const handleButtonClick = (event: React.MouseEvent, report: Report) => {
    event.stopPropagation();
    navigate(`/report/${report.reportId}`);
  };

  const formatJalaliDate = (gregorianDate: string) => {
    const date = new Date(gregorianDate);
    const formatter = new Intl.DateTimeFormat('fa-IR', {
      dateStyle: 'full',
    });
    const formattedDate = formatter.format(date);
    const dateParts = formattedDate.split(' ');
    const day = dateParts[0].replace(',', '');
    const month = dateParts[1].replace(',', '');
    const year = dateParts[2].replace(',', '');

    return `${year} ${month} ${day}`;
  };

  return (
    <Box
      p={{ base: '2', md: '4' }}
      className="scroll-table"
      py="32px"
      bg="white"
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.medium}
              color="rgba(37, 44, 88,0.5)"
              px="40px"
              pb="18px"
            >
              تاریخ
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.medium}
              color="rgba(37, 44, 88,0.5)"
              px="40px"
              pb="18px"
            >
              کشور مقصد
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.medium}
              color="rgba(37, 44, 88,0.5)"
              px="40px"
              pb="18px"
            >
              فیلدهای مورد علاقه
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.medium}
              color="rgba(37, 44, 88,0.5)"
              px="40px"
              pb="18px"
            >
              وضعیت
            </Th>
            <Th
              borderBottom="2.98px solid #D5D9DD"
              fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
              fontFamily={theme.fonts.family.medium}
              color="rgba(37, 44, 88,0.5)"
              px="40px"
              pb="18px"
              textAlign="left"
            >
              لیست اساتید
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((report, index) => (
            <Tr
              key={report.id}
              onClick={
                report.status === 'PENDING' || report.status === 'FAILED'
                  ? undefined
                  : () => handleRowClick(report)
              }
              cursor={
                report.status === 'PENDING' || report.status === 'FAILED'
                  ? 'default'
                  : 'pointer'
              }
              color={
                report.status === 'PENDING' || report.status === 'FAILED'
                  ? 'rgba(0, 0, 0, 0.5)'
                  : 'rgba(0, 0, 0, 1)'
              }
              _hover={{
                color:
                  report.status === 'PENDING' || report.status === 'FAILED'
                    ? 'rgba(0, 0, 0, 0.5)'
                    : `${theme.colors.texts.textHover}`,
              }}
            >
              <Td
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                fontFamily={theme.fonts.family.medium}
                px="40px"
                pt="34px"
                pb="23px"
                borderBottom={
                  index === data.length - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {formatJalaliDate(report.createdDate)}
              </Td>
              <Td
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                fontFamily={theme.fonts.family.medium}
                px="40px"
                py="23px"
                borderBottom={
                  index === data.length - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {report.country}
              </Td>
              <Td
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                fontFamily={theme.fonts.family.medium}
                px="40px"
                py="23px"
                borderBottom={
                  index === data.length - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {report.interestList.join('  -  ')}
              </Td>
              <Td
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                fontFamily={theme.fonts.family.medium}
                px="40px"
                py="23px"
                textAlign="right"
                borderBottom={
                  index === data.length - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                {report.status === 'DONE' ? 'Ready' : report.status}
              </Td>
              <Td
                fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                fontFamily={theme.fonts.family.medium}
                px="40px"
                py="23px"
                textAlign="left"
                borderBottom={
                  index === data.length - 1 ? 'none' : '1px solid #E2E8F0'
                }
              >
                <Button
                  bg={
                    report.status === 'PENDING' || report.status === 'FAILED'
                      ? '#e2e8f0'
                      : '#D4E6FC'
                  }
                  p="8px"
                  h="auto"
                  gap="8px"
                  cursor={
                    report.status === 'PENDING' || report.status === 'FAILED'
                      ? 'default'
                      : 'pointer'
                  }
                  onClick={
                    report.status === 'PENDING' || report.status === 'FAILED'
                      ? undefined
                      : (event) => handleButtonClick(event, report)
                  }
                >
                  <FolderIcon width="16px" height="auto" />
                  <Text
                    fontSize={`clamp(${theme.fonts.size.vlil}, 1.5vw, ${theme.fonts.size.lil})`}
                    fontFamily={theme.fonts.family.body}
                    color="#17365A"
                  >
                    مشاهده لیست
                  </Text>
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default LastReportsTable;
