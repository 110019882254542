import React, { useEffect, useState } from 'react';
import { Box, Link } from '@chakra-ui/react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import theme from '../../theme';
import PlanPrice from '../plans/PlanPrice';

interface NavMenuProps {
  title: string;
  TextColor: string;
}

const NavMenu: React.FC<NavMenuProps> = ({ title, TextColor }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  // Map titles to paths
  const paths: { [key: string]: string } = {
    خانه: '/',
    'سوالات متداول': '/faq',
    وبلاگ: '/blog',
    'درباره ما': '/aboutus',
    'تماس با ما': '/contactus',
    'انواع اشتراک': '/plans',
  };

  const currentPath = paths[title];

  // Handle navigation based on title
  const handleNavigation = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // Prevent default navigation

    if (currentPath === '/plans') {
      setPreviousPath(location.pathname); // Save the current path
      setIsModalOpen(true); // Open the modal
      window.history.pushState(null, '', '/plans'); // Update the URL path to /plans
    } else if (currentPath === '/blog') {
      window.open('https://blog.applytory.com', '_blank');
    } else {
      navigate(currentPath); // Navigate to the specified path
    }
  };

  // Close modal and revert URL on modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (previousPath) {
      navigate(previousPath); // Navigate back to the previous path
    } else {
      navigate('/'); // Default to home if no previous path
    }
  };

  // Scroll to top on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box>
      <NavLink to={currentPath}>
        <Link
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.medium}
          color={
            location.pathname === currentPath
              ? theme.colors.texts.textHover
              : TextColor
          } // Highlight if current path
          _hover={{
            textDecoration: 'none',
            color: theme.colors.texts.textHover,
          }}
          onClick={handleNavigation} // Ensure navigation occurs before scrolling
        >
          {title}
        </Link>
      </NavLink>
      {isModalOpen && (
        <PlanPrice isOpen={isModalOpen} onClose={handleCloseModal} />
      )}
    </Box>
  );
};

export default NavMenu;
