import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Link,
  Box,
  HStack,
  IconButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';

import { ReactComponent as CloseModalIcon } from 'src/assets/mobile/icons/CloseModal.svg';
import { ReactComponent as SuccessReqImage } from 'src/assets/mobile/images/SuccessReqImage.svg';
import { ReactComponent as LikedIcon } from 'src/assets/mobile/icons/liked.svg';
import { ReactComponent as DislikeIcon } from 'src/assets/mobile/icons/dislike.svg';
import { ReactComponent as DislikedIcon } from 'src/assets/mobile/icons/disliked.svg';
import theme from 'src/theme';
import { useNavigate } from 'react-router-dom';

interface Professor {
  id: number;
  author: string;
  scholarId: string | null;
  urlPicture: string;
  affiliation: string;
  email: string | null;
  interests: string[];
  authorCitations: number;
  university: string | null;
  country: string;
  universityWorldRank: number;
  universityCountryRank: number;
  interestSimilarityScore: number;
}

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    navigate('/');
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent borderTopRadius="lg" position="relative" minWidth="100%">
        <ModalBody>
          <VStack
            align="center"
            width="100%"
            marginTop="8px"
            marginBottom="16px"
          >
            <SuccessReqImage />
            <Text>درخواست شما باموفقیت ثبت شد</Text>
            <Text
              backgroundColor={theme.colors.mobile.secondary}
              fontFamily={theme.fonts.family.body}
              fontWeight="400"
              fontSize={theme.fonts.size.small}
              color={theme.colors.secondary}
              padding="8px 12px"
              borderRadius="8px"
              textAlign="justify"
            >
              پس از آماده سازی لیست درخواستی از طریق ایمیل شما را مطلع میسازیم و
              پس از آن می‌توانید از منو گزارش ها لیست خود را دریافت کنید.
            </Text>
          </VStack>
        </ModalBody>
        <Flex width="100%" backgroundColor="#FFFFFF" padding="16px">
          <Button
            onClick={handleOnclick}
            width="100%"
            backgroundColor="#EBEBF0"
            borderRadius="8px"
            h="48px"
            color={theme.colors.secondary}
            _hover={{
              backgroundColor: theme.colors.secondary,
              color: theme.colors.texts.white,
            }}
          >
            متوجه شدم
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
