import React from 'react';
import { Box, Text, Icon } from '@chakra-ui/react';

interface ActionButtonProps {
  text: string;
  icon: React.ElementType;
  onClick: () => void;
  variant?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  icon: IconComponent,
  onClick,
  variant = 'outline',
}) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      backgroundColor="#FFFFFF"
      border="1px solid #EBEBF0"
      borderRadius="8px"
      alignItems="flex-start"
      justifyContent="center"
      textAlign="start"
      width="100%"
      padding="12px"
      cursor="pointer"
      _hover={{
        backgroundColor: '#f7f7f7',
      }}
      _active={{
        backgroundColor: '#E2E8F0',
        transform: 'scale(0.98)',
      }}
      _focus={{
        boxShadow: '0 0 0 1px rgba(66,153,225,0.6)',
      }}
    >
      <Icon as={IconComponent} width="24px" height="24px" marginRight="8px" />
      <Text>{text}</Text>
    </Box>
  );
};

export default ActionButton;
