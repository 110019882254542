// PlanPrice.tsx
import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { fetchPlanData } from './fetchPlanData';
import { handlePayment } from './handlePayment';
import PlanList from './PlanList';
import Invoice from './Invoice';
import { AuthContext } from '../../context/AuthContext';
import AuthModal from '../auth/AuthModal';
import { AxiosError } from 'axios';

interface PlanPriceProps {
  isOpen: boolean;
  onClose: () => void;
}

const PlanPrice: React.FC<PlanPriceProps> = ({ isOpen, onClose }) => {
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any | null>(null);
  const [planData, setPlanData] = useState<any[]>([]);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const toast = useToast({
    position: 'top',
    containerStyle: {
      textAlign: 'center',
      width: '500px',
      maxWidth: '100%',
    },
  });

  const { isAuthenticated } = useContext(AuthContext) || {};

  const handlePlanSelect = (plan: any) => {
    if (!isAuthenticated) {
      setAuthModalOpen(true);
      return;
    }
    if (plan.planID == 1) {
      onClose();
    } else {
      setSelectedPlan(plan);
      setShowInvoice(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Only fetch data if the modal is open
      setLoading(true);
      const loadPlanData = async () => {
        try {
          await fetchPlanData(setLoading, setPlanData);
        } catch (error) {
          toast({
            description: (error as AxiosError).message,
            status: 'error',
            duration: 3000,
            containerStyle: {
              textAlign: 'center',
              fontWeight: 'medium',
            },
            isClosable: true,
          });
        } finally {
          setLoading(false); // Ensure loading is set to false regardless of success or failure
        }
      };

      loadPlanData();
    }
  }, [isOpen]); // Depend on isOpen to avoid unnecessary fetches

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          width={['90vw', '80vw', '1000px']}
          maxWidth={['90vw', '80vw', '1100px']}
          height="auto"
          mt={['30px', '30px', 'auto']}
          minHeight="640px"
          background="linear-gradient(360deg, rgba(247, 247, 247, 0.8) -3.17%, rgba(51, 61, 85, 1) 111.56%)"
          borderRadius="16px"
          padding={['10px', '20px', '40px 151px']}
          position="relative"
          zIndex={1}
          overflow="hidden"
        >
          <ModalBody
            padding="0px"
            m="0px"
            display="flex"
            justifyContent="center"
            height="auto"
          >
            {isDesktop ? (
              <>
                <motion.div
                  animate={{ x: showInvoice ? '200%' : '0%' }}
                  transition={{ duration: 0.5 }}
                >
                  <PlanList
                    planData={planData}
                    loading={loading}
                    onClose={onClose}
                    handlePlanSelect={handlePlanSelect}
                  />
                </motion.div>
                <motion.div
                  initial={{ x: showInvoice ? '-1000px' : '-1000px' }}
                  animate={{ x: showInvoice ? '661px' : '-1000px' }}
                  transition={{ duration: 0.5 }}
                >
                  <Invoice
                    selectedPlan={selectedPlan}
                    onBack={() => setShowInvoice(false)}
                    handlePayment={(plan, voucher, discount) =>
                      handlePayment(plan, voucher, discount)
                    }
                  />
                </motion.div>
              </>
            ) : showInvoice ? (
              <Invoice
                selectedPlan={selectedPlan}
                onBack={() => setShowInvoice(false)}
                handlePayment={(plan, voucher, discount) =>
                  handlePayment(plan, voucher, discount)
                }
              />
            ) : (
              <PlanList
                planData={planData}
                loading={loading}
                onClose={onClose}
                handlePlanSelect={handlePlanSelect}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <AuthModal
        isOpen={authModalOpen}
        onClose={() => {
          setAuthModalOpen(false);
          if (isAuthenticated) {
            setShowInvoice(true);
          }
        }}
      />
    </>
  );
};

export default PlanPrice;
