import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Text, Container } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import theme from '../theme';
import FreeProfRes from '../components/reports/FreeProfRes';
import PlanedProfRes from '../components/reports/PlanedProfRes';
import Loader from '../components/Loader';
import { getReportWithId } from '../services/reportService';

// Define the type for your report data
interface ReportData {
  country: string;
  createdDate: string;
  interestList: string[];
  reportTypeId: number;
}

const Report: React.FC = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReport = async () => {
      if (!reportId) {
        setLoading(false);
        return;
      }

      try {
        const response = await getReportWithId(reportId);
        const { country, createdDate, interestList, reportTypeId } = response;

        setReportData({
          country,
          createdDate,
          interestList,
          reportTypeId,
        });
        console.log('reportTypeId');
        console.log(reportTypeId);
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [reportId]);

  if (loading) {
    return <Loader color1="#56A2FF" color2="#FFF" size={200} />;
  }

  if (!reportData) {
    return <Text>Report not found</Text>;
  }

  const { country, createdDate, interestList, reportTypeId } = reportData;

  return (
    <ChakraProvider theme={theme}>
      <Box
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        pt="58px"
        bg={theme.colors.pbg}
      >
        <Container
          width="100%"
          left="calc((100vw - 1225px) / 2)"
          minWidth="1225px"
          zIndex={2}
        >
          <Text
            textAlign="right"
            fontFamily={theme.fonts.family.body}
            fontWeight="500"
            fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
            lineHeight="37px"
            color="#252C58"
            mb="27px"
          >
            لیست اساتید
          </Text>
          {reportTypeId == 2 ? (
            <PlanedProfRes
              country={country}
              createdDate={createdDate}
              interestList={interestList}
            />
          ) : (
            <FreeProfRes
              country={country}
              createdDate={createdDate}
              interestList={interestList}
            />
          )}
        </Container>
      </Box>
    </ChakraProvider>
  );
};

export default Report;
