import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import theme from '../../theme';
import { ReactComponent as LinePattern } from '../../assets/images/LinePattern.svg';
import { ReactComponent as LaptopSVG } from '../../assets/images/Laptop.svg';
import DropdownWithCheckboxes from '../../components/header/DropdownIntrestsCheckboxes';
import FileUpload from '../../components/header/FileUpload';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import { generateReport, getReportBalance } from '../../services/reportService';
import '../../res_style.css';
import DropdownRankCheckbox from '../../components/header/DropdownRankCheckbox';
import AuthModal from '../../components/auth/AuthModal';
import SuccessSearch from '../../components/header/SuccessSearch';
import PlanPrice from '../../components/plans/PlanPrice';
import Loader from '../../components/Loader'; // Import the Loader component
import DropdownCountryCheckboxes from '../../components/header/DropdownCountryCheckboxes';
import { useNavigate } from 'react-router-dom';
import { handleUnauthorizedError } from '../../services/authService';
import { AxiosError } from 'axios';

type Option = {
  value: string;
  label: string;
};

type HeaderSearchBoxProps = {
  searchPlaceholder: string;
};

const HeaderSearchBox: React.FC<HeaderSearchBoxProps> = ({
  searchPlaceholder,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isOutofBallanceOpen, setIsOutofBallanceOpen] = useState(false);
  const [isPlanPriceOpen, setIsPlanPriceOpen] = useState(false);
  const [reportBalance, setReportBalance] = useState<number>(0); // State for report balance
  const [reportType, setReportType] = useState<number>(0); // State for report balance
  const [loading, setLoading] = useState(false); // State for loading
  const [initialSearchData, setInitialSearchData] = useState<{
    interests: string[];
    countries: string;
    universityRank: string;
    file: File | null;
  } | null>(null); // State for storing initial search data
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const toast = useToast({
    position: 'top',
    containerStyle: {
      color: 'red',
      width: '500px',
      maxWidth: '100%',
    },
  });

  const {
    destinationCountries,
    universityRankOptions,
    UniversityRankSelected,
    IntrestSelected,
    CountriesSelected,
    setIntrestSelected,
    setCountriesSelected,
    setUniversityRankSelected,
    loading: contextLoading, // Use loading from the context
  } = appContext || {};

  const { isAuthenticated, user } = authContext || {};

  const fetchBalance = useCallback(async () => {
    if (user) {
      try {
        const data = await getReportBalance();
        if (data.body && data.body.length > 0) {
          setReportBalance(data.body[0].reportBalance);
          setReportType(data.body[0].reportType.id);
        } else {
          console.error('Invalid response from getReportBalance:', data);
          setIsOutofBallanceOpen(true); // Handle the case where the response does not match expectations
        }
      } catch (error) {
        handleUnauthorizedError(error as AxiosError);
        console.error('Error fetching report balance:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchBalance();
    }
  }, [isAuthenticated, fetchBalance]);

  useEffect(() => {
    if (initialSearchData && isAuthenticated) {
      if (setIntrestSelected) {
        setIntrestSelected(initialSearchData.interests);
      }
      if (setCountriesSelected) {
        let countryValue = initialSearchData.countries;
        if (initialSearchData.countries === 'united states of america') {
          countryValue = 'usa';
        } else if (initialSearchData.countries === 'united kingdom') {
          countryValue = 'uk';
        } else {
          countryValue = initialSearchData.countries;
        }
        // const countryValue =
        //   initialSearchData.countries === 'united states of america'
        //     ? 'usa'
        //     : initialSearchData.countries;
        setCountriesSelected(countryValue);
      }
      if (setUniversityRankSelected) {
        setUniversityRankSelected(initialSearchData.universityRank);
      }
      setUploadedFile(initialSearchData.file);
      setInitialSearchData(null);
    }
  }, [
    isAuthenticated,
    initialSearchData,
    setIntrestSelected,
    setCountriesSelected,
    setUniversityRankSelected,
  ]);

  const handleFileUpload = (file: File) => {
    setUploadedFile(file);
  };

  const handleConfirmIntrestSelected = (selectedValues: string[]) => {
    setIntrestSelected && setIntrestSelected(selectedValues);
  };

  const handleConfirmCountriesSelected = (selectedValues: string) => {
    let countryValue = selectedValues;
    if (selectedValues === 'united states of america') {
      countryValue = 'usa';
    } else if (selectedValues === 'united kingdom') {
      countryValue = 'uk';
    } else {
      countryValue = selectedValues;
    }
    // const countryValue =
    //   selectedValues === 'united states of america' ? 'usa' : selectedValues;
    setCountriesSelected && setCountriesSelected(countryValue);
  };

  const handleConfirmUniversityRank = (selectedValue: string) => {
    setUniversityRankSelected && setUniversityRankSelected(selectedValue);
  };

  const fetchDestinationCountries = async (): Promise<Option[]> => {
    return destinationCountries || [];
  };

  const fetchUniversityRank = async (): Promise<Option[]> => {
    return universityRankOptions || [];
  };

  const SearchAction = useCallback(async () => {
    if (
      !IntrestSelected ||
      IntrestSelected.length === 0 ||
      !CountriesSelected ||
      CountriesSelected.length === 0 ||
      !UniversityRankSelected ||
      UniversityRankSelected.length === 0 ||
      !uploadedFile
    ) {
      toast({
        description: 'لطفا تمامی فیلدها را پر کنید.',
        status: 'error',
        duration: 3000,
        variant: 'left-accent',
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
      });
      return;
    }

    if (!isAuthenticated) {
      setInitialSearchData({
        interests: IntrestSelected || [],
        countries: CountriesSelected || '',
        universityRank: UniversityRankSelected || '',
        file: uploadedFile,
      });
      onOpen();
      return;
    }

    try {
      const data = await getReportBalance();
      if (data.body && data.body.length > 0) {
        const latestBalance = data.body[0].reportBalance;

        // check if it had type 2. first use 2
        let indexToUse = 0;
        // Iterate over the data.body array
        for (let i = 0; i < data.body.length; i++) {
          console.log('data.body[i]');
          console.log(i);
          console.log(data.body[i]);
          if (data.body[i].reportType.id === 2) {
            indexToUse = i; // Use this index if reportType.id is 2
            break; // Exit loop once we find the desired reportType.id
          }
        }
        // const latestReportType = data.body[0].reportType.id;
        const latestReportType = data.body[indexToUse].reportType.id;
        console.log('Selected reportType.id:', latestReportType);
        setReportBalance(latestBalance);
        setReportType(latestReportType);

        if (latestBalance <= 0) {
          setIsOutofBallanceOpen(true);
          return;
        }

        let minUniRank = 0;
        let maxUniRank = 0;

        if (UniversityRankSelected) {
          const selectedRank = UniversityRankSelected;
          const rankRange = selectedRank.split(' - ');
          minUniRank =
            rankRange[0] === '∞' ? Infinity : parseInt(rankRange[0], 10);
          maxUniRank =
            rankRange[1] === '∞' ? Infinity : parseInt(rankRange[1], 10);
        }

        const filter = {
          reportTypeId: latestReportType,
          interestList: IntrestSelected || [],
          minUniRank: minUniRank,
          maxUniRank: maxUniRank,
          country: CountriesSelected ? CountriesSelected : '',
        };

        setLoading(true); // Set loading state to true
        const reportData = await generateReport(uploadedFile, filter);
        console.log('Report generated successfully:', reportData);
        setIsSuccessOpen(true); // Show success modal

        // Reset the input states
        setIntrestSelected && setIntrestSelected([]);
        setCountriesSelected && setCountriesSelected('');
        setUniversityRankSelected && setUniversityRankSelected('');
        setUploadedFile(null);
      } else {
        console.error('Invalid response from getReportBalance:', data);
        setIsOutofBallanceOpen(true); // Handle the case where the response does not match expectations
      }
    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  }, [
    isAuthenticated,
    IntrestSelected,
    CountriesSelected,
    UniversityRankSelected,
    uploadedFile,
    onOpen,
    toast,
    setIntrestSelected,
    setCountriesSelected,
    setUniversityRankSelected,
  ]);

  useEffect(() => {
    const handlePostAuthentication = async () => {
      if (isAuthenticated && initialSearchData) {
        await fetchBalance(); // Ensure balance is fetched
        await SearchAction(); // Proceed with the search action
      }
    };

    handlePostAuthentication();
  }, [isAuthenticated, initialSearchData, fetchBalance, SearchAction]);

  if (!appContext || !authContext) {
    return null;
  }
  return (
    <Flex
      bg="white"
      justifyContent="space-between"
      textAlign="right"
      boxShadow="0px 20px 100px rgba(34, 34, 34, 0.15)"
      position="relative"
      className="search_box"
      flexDirection={{ base: 'column', md: 'row' }}
      zIndex="1"
    >
      {(loading || contextLoading) && (
        <Loader color1="#56A2FF" color2="#FFF" size={200} />
      )}
      <Box
        pl={{ base: '16px', md: '126px' }}
        width={{ base: '90%', md: '100%' }}
        textAlign="right"
        mb={{ base: '20px', md: '0' }}
        position="relative"
        zIndex="1"
      >
        <Text
          fontFamily={theme.fonts.family.ultra}
          fontSize={{ base: theme.fonts.size.medium, md: theme.fonts.size.big }}
          color={theme.colors.texts.dark}
          marginBottom="30px"
        >
          {searchPlaceholder}
        </Text>
        <DropdownWithCheckboxes
          placeholder="زمینه مورد علاقه"
          onConfirm={handleConfirmIntrestSelected}
        />
        <DropdownCountryCheckboxes
          fetchOptions={fetchDestinationCountries}
          placeholder="کشور"
          onConfirm={handleConfirmCountriesSelected}
        />
        <DropdownRankCheckbox
          fetchOptions={fetchUniversityRank}
          placeholder="رنک دانشگاه"
          onConfirm={handleConfirmUniversityRank}
        />
        <FileUpload onFileUpload={handleFileUpload} />
        <Button
          mt="30px"
          borderRadius="0px"
          backgroundColor={theme.colors.texts.secondary}
          fontFamily={theme.fonts.family.bold}
          fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
          color={theme.colors.texts.white}
          padding="14px 36.5px"
          _hover={{
            backgroundColor: theme.colors.hover,
          }}
          onClick={SearchAction}
        >
          جستجو
        </Button>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        className="line_image"
      >
        {/* Hide on mobile */}
        <LinePattern />
      </Box>
      <Box
        // display={{ base: 'none', md: 'block' }}
        position="absolute"
        className="search_image"
      >
        {/* Hide on mobile */}
        <LaptopSVG width="180px" height="180px" />
      </Box>
      <AuthModal isOpen={isOpen} onClose={onClose} />
      {isSuccessOpen && (
        <SuccessSearch
          title="درخواست شما با موفقیت ثبت شد"
          description="تا لحظاتی دیگر می‌توانید از طریق حساب کاربری، لیست جستجوهای اخیر و یا لینک پیامک شده به لیست خود دسترسی داشته باشید"
          iconType="success"
          isOpen={isSuccessOpen}
          onClose={() => {
            setIsSuccessOpen(false);
            navigate('/lastreports');
          }}
        />
      )}
      {isOutofBallanceOpen && (
        <SuccessSearch
          title="اتمام کوپن"
          description=" شما تمام کوپن های گزارش خود را استفاده کردید. در صورت نیاز به دریافت گزارش جدید اشتراک بتا را تهیه کنید."
          iconType="error"
          isOpen={isOutofBallanceOpen}
          onClose={() => {
            setIsOutofBallanceOpen(false);
            setIsPlanPriceOpen(true);
          }}
        />
      )}

      <PlanPrice
        isOpen={isPlanPriceOpen}
        onClose={() => setIsPlanPriceOpen(false)}
      />
    </Flex>
  );
};

export default HeaderSearchBox;
