// AuthContext.tsx

import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { AuthContextType } from '../@types/AuthContextType';
import {
  sendOtp as sendOtpAPI,
  verifyOtp as verifyOtpAPI,
  logout as logoutService,
} from '../services/authService';

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<string | null>(null);

  const checkAuth = () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setIsAuthenticated(true);
      setUser(localStorage.getItem('user'));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const login = (username: string) => {
    setIsAuthenticated(true);
    setUser(username);
    localStorage.setItem('user', username);
    setIsLoading(false);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    logoutService();
    setIsLoading(false);
  };

  const sendOtp = async (email: string): Promise<void> => {
    try {
      await sendOtpAPI(email);
    } catch (error) {
      console.error('Error sending OTP:', error);
      throw error;
    }
    setIsLoading(false);
  };

  const verifyOtp = async (email: string, otpCode: string): Promise<void> => {
    try {
      await verifyOtpAPI(email, otpCode);
      login(email);
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw error;
    }
    setIsLoading(false);
  };

  const contextValue: AuthContextType = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    login,
    logout,
    sendOtp,
    verifyOtp,
    isLoading,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
