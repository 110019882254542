import React, { useState, useContext, useEffect } from 'react';
import {
  VStack,
  Text,
  Button,
  Divider,
  Box,
  PinInput,
  Flex,
  PinInputField,
  useToast,
} from '@chakra-ui/react';

import { AuthContext } from 'src/context/AuthContext';
import axios from 'axios';

import theme from 'src/theme';
import { ReactComponent as GoogleIcon } from 'src/assets/icons/Google.svg';
import { ReactComponent as LoginImage } from 'src/assets/mobile/images/LoginImage.svg';
import { ReactComponent as ArrowRight } from 'src/assets/mobile/icons/ArrowRight.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/mobile/icons/ErrorIcon.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { redirectToGoogleAuth } from 'src/services/GoogleAuth';
import FloatingLabelInput from 'src/components/mobile/FloatingLabelInput';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPinValid, setIsPinValid] = useState(true);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(60);

  const toast = useToast({
    position: 'top',
    containerStyle: {
      textAlign: 'center',
      width: '500px',
      maxWidth: '100%',
    },
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (step === 2) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            onTimerEnd();
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [step]);

  if (!authContext) {
    return null;
  }

  const { sendOtp, verifyOtp } = authContext;

  const handleEmailSubmit = async () => {
    if (!email) {
      setIsEmailValid(false);
      toast({
        title: 'ورودی ایمیل خالی',
        description: 'لطفا ایمیل خود را وارد کنید.',
        status: 'error',
        duration: 3000,
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
        isClosable: true,
      });
      return;
    }

    if (!isValidEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    try {
      await sendOtp(email);
      setStep(2);
      setTimer(60); // Reset timer when moving to step 2
    } catch (error) {
      console.error('Failed to send OTP:', error);

      // Check if error is an instance of AxiosError
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 429) {
          toast({
            title: 'خطا',
            description:
              'درخواست با این ایمیل زیاد بود. لطفا کمی بعد دوباره تلاش کنید.',
            status: 'error',
            duration: 3000,
            containerStyle: {
              textAlign: 'center',
              fontFamily: `${theme.fonts.family.body}`,
              fontWeight: 'medium',
            },
            isClosable: true,
          });
        } else {
          toast({
            title: 'خطا',
            description: 'خطایی در ارسال کد پیش آمد. لطفا دوباره تلاش کنید.',
            status: 'error',
            duration: 3000,
            containerStyle: {
              textAlign: 'center',
              fontFamily: `${theme.fonts.family.body}`,
              fontWeight: 'medium',
            },
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'خطا',
          description: 'خطایی در ارسال کد پیش آمد. لطفا دوباره تلاش کنید.',
          status: 'error',
          duration: 3000,
          containerStyle: {
            textAlign: 'center',
            fontFamily: `${theme.fonts.family.body}`,
            fontWeight: 'medium',
          },
          isClosable: true,
        });
      }
    }
  };

  const handleCodeSubmit = async () => {
    try {
      await verifyOtp(email, code);
      toast({
        title: 'خوش آمدید',
        description: 'ورود موفقیت آمیز بود',
        status: 'success',
        duration: 3000,
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
        isClosable: true,
      });
      navigate('/');
    } catch (error) {
      console.error('Failed to verify OTP:', error);
      setIsPinValid(false);
      toast({
        title: 'خطا',
        description: 'لطفا کد ارسال شده را دوباره بررسی کنید.',
        status: 'error',
        duration: 3000,
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
        isClosable: true,
      });
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEmailSubmit();
    }
  };

  const onTimerEnd = () => {
    setStep(1); // Reset to step 1 when the timer ends
    setCode(''); // Optionally reset the code
    setTimer(60); // Reset the timer for the next render
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" p="16px">
      <Box display="flex" flexDirection="column" flex="1">
        <VStack width="100%" flex="1" spacing="16px">
          <Box mt="72px" mb="24px">
            <LoginImage width="150px" />
          </Box>
          <AnimatePresence mode="wait">
            {step === 1 && (
              <motion.div
                key="step1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1',
                }}
              >
                <Box flex="1" p="16px">
                  <Text
                    fontFamily={theme.fonts.family.medium}
                    fontWeight="500"
                    fontSize={theme.fonts.size.medium}
                    color={theme.colors.texts.mobilecolor}
                    mb="16px"
                  >
                    به اپلای طوری خوش آمدید
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.medium}
                    fontWeight="400"
                    fontSize={theme.fonts.size.small}
                    color={theme.colors.texts.mobilecolor}
                    mb="16px"
                  >
                    برای ورود و یا ثبت نام ایمیل خود را وارد نمایید و یا از طریق
                    گوگل اکانت خود وارد شوید
                  </Text>
                  <FloatingLabelInput
                    id="fname"
                    name="fname"
                    label="ایمیل"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsEmailValid(true);
                    }}
                    handleKeyDown={(e) => handleKeyDown(e)}
                    isValid={isEmailValid}
                  />
                  {!isEmailValid && (
                    <Flex mt="4px">
                      <ErrorIcon />
                      <Text color="#BD272D" fontSize="sm" mr="8px">
                        فرمت ورودی ایمیل اشتباه است
                      </Text>
                    </Flex>
                  )}
                  <Divider my="16px" />
                  <Button
                    variant="outline"
                    width="100%"
                    height="auto"
                    borderRadius="8px"
                    padding="12px 0px"
                    fontFamily={theme.fonts.family.body}
                    fontWeight="400"
                    fontSize={theme.fonts.size.small}
                    color={theme.colors.texts.mobilecolor}
                    border="solid 1px #D0D5DD"
                    boxShadow="0px 1px 2px 0px #1018280D"
                    onClick={redirectToGoogleAuth}
                    mb="32px"
                  >
                    <Text mt="6px" ml="12px">
                      ورود با گوگل
                    </Text>
                    <GoogleIcon />
                  </Button>
                </Box>
                <Flex
                  position="fixed"
                  bottom="0"
                  left="0"
                  width="100%"
                  backgroundColor="#FFFFFF"
                  padding="16px"
                  height="80px"
                  minHeight="80px"
                >
                  <Button
                    onClick={handleEmailSubmit}
                    width="100%"
                    backgroundColor={email ? theme.colors.secondary : '#EBEBF0'}
                    borderRadius="8px"
                    h="48px"
                    color={
                      email ? theme.colors.texts.white : theme.colors.secondary
                    }
                    mt="auto"
                    _hover={{
                      backgroundColor: `${
                        email ? theme.colors.secondary : '#EBEBF0'
                      }`,
                      color: `${
                        email
                          ? theme.colors.texts.white
                          : theme.colors.secondary
                      }`,
                    }}
                  >
                    تایید
                  </Button>
                </Flex>
              </motion.div>
            )}

            {step === 2 && (
              <motion.div
                key="step2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{ width: '100%' }}
              >
                <Box
                  position="absolute"
                  top="23px"
                  right="27px"
                  onClick={() => setStep(1)}
                >
                  <ArrowRight />
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  marginTop="24px"
                ></Box>
                <Text
                  color={theme.colors.texts.secondary}
                  marginTop="16px"
                  px="16px"
                >
                  کد تایید به ایمیل شما فرستاده شد.
                </Text>
                <Flex
                  justifyContent="space-between"
                  mb="12px"
                  mt="16px"
                  dir="ltr"
                  p="16px"
                >
                  <PinInput
                    value={code}
                    onChange={(e) => {
                      setCode(e);
                      setIsPinValid(true);
                    }}
                    type="number"
                  >
                    {Array(6)
                      .fill('')
                      .map((_, index) => (
                        <PinInputField
                          key={index}
                          placeholder=""
                          w="40px"
                          h="40px"
                          border="solid 1.6px"
                          borderColor={
                            code.length > index
                              ? isPinValid
                                ? '#3080e2 !important'
                                : '#BD272D !important'
                              : '#BCBCC0 !important'
                          }
                          borderRadius="12px"
                          _placeholder={{ color: 'transparent' }}
                        />
                      ))}
                  </PinInput>
                </Flex>
                <Text
                  textAlign="center"
                  color={theme.colors.texts.secondary}
                  mb="24px"
                >
                  شکبیا باشید {formatTime(timer)}
                </Text>
                <Button
                  onClick={handleCodeSubmit}
                  width="100%"
                  backgroundColor={
                    code.length === 6 ? theme.colors.secondary : '#EBEBF0'
                  }
                  borderRadius="8px"
                  h="48px"
                  color={
                    code.length === 6
                      ? theme.colors.texts.white
                      : theme.colors.secondary
                  }
                  mt="auto"
                  mb="16px"
                  _hover={{
                    backgroundColor: `${
                      code.length === 6 ? theme.colors.secondary : '#EBEBF0'
                    }`,
                    color: `${
                      code.length === 6
                        ? theme.colors.texts.white
                        : theme.colors.secondary
                    }`,
                  }}
                >
                  تایید
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </VStack>
      </Box>
    </Box>
  );
};

export default Login;
