import React, { useState, useEffect, useContext } from 'react';
import { ChakraProvider, Box, Text, Container } from '@chakra-ui/react';
import theme from '../theme';
import LastReportsTable from '../components/LastReports/LastReportsTable';
import { getAllReports } from '../services/reportService';
import Loader from '../components/Loader';
import { AuthContext } from '../context/AuthContext';

// Define the type for your report data
interface Report {
  createdDate: string;
  id: string;
  reportId: string;
  country: string;
  interestList: string[];
  reportTypeId: number;
  status: string;
}

const LastReports: React.FC = () => {
  const [reportsData, setReportsData] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!authContext) {
      return;
    }

    const fetchReports = async () => {
      try {
        const response = await getAllReports(0, 100);
        let reports: Report[] = response.body.content.map((report: any) => ({
          id: report.id,
          reportId: report.reportId,
          createdDate: report.createdDate,
          country: report.country,
          interestList: report.interestList,
          reportTypeId: report.reportTypeId,
          status: report.status,
        }));

        // Sort reports by createdDate in descending order
        reports = reports.sort(
          (a, b) =>
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
        );

        setReportsData(reports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [authContext]);

  if (!authContext) {
    return (
      <ChakraProvider>
        <Box
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          pt="58px"
          bg={theme.colors.pbg}
        >
          <Container
            width="100%"
            left="calc((100vw - 1225px) / 2)"
            minWidth="1225px"
            zIndex={2}
          >
            <Text
              textAlign="center"
              fontFamily={theme.fonts.family.body}
              fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
              lineHeight="37px"
              color="#252C58"
              mb="48px"
            >
              Unable to determine authentication status. Please try again later.
            </Text>
          </Container>
        </Box>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider>
      <Box
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        pt="58px"
        bg={theme.colors.pbg}
      >
        <Container
          width="100%"
          left="calc((100vw - 1225px) / 2)"
          minWidth="1225px"
          zIndex={2}
        >
          <Text
            textAlign="right"
            fontFamily={theme.fonts.family.body}
            fontWeight="500"
            fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
            lineHeight="37px"
            color="#252C58"
            mb="48px"
          >
            لیست جستجوهای اخیر
          </Text>
          {loading ? (
            <Loader color1="#56A2FF" color2="#FFF" size={200} />
          ) : (
            <>
              {!authContext.isAuthenticated ? (
                <Text
                  textAlign="center"
                  fontFamily={theme.fonts.family.body}
                  fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
                  lineHeight="37px"
                  color="#252C58"
                  mb="48px"
                >
                  برای مشاهده گزارش ها باید وارد شوید.
                </Text>
              ) : reportsData.length === 0 ? (
                <Text
                  textAlign="center"
                  fontFamily={theme.fonts.family.body}
                  fontSize={`clamp(${theme.fonts.size.semimedium}, 1.5vw, ${theme.fonts.size.big})`}
                  lineHeight="37px"
                  color="#252C58"
                  mb="48px"
                >
                  هیچ گزارشی یافت نشد. صبور باشید
                </Text>
              ) : (
                <LastReportsTable data={reportsData} />
              )}
            </>
          )}
        </Container>
      </Box>
    </ChakraProvider>
  );
};

export default LastReports;
