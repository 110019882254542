import React, { useContext } from 'react';
import {
  Flex,
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import NavMenu from '../../components/header/NavMenu';
import { ReactComponent as Logo } from '../../assets/images/SecLogo.svg';
import AuthButtonGroup from '../../components/header/AuthButtonGroup';
import { AppContext } from '../../context/AppContext';
import '../../res_style.css';
import theme from '../../theme';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseModal } from '../../assets/icons/CloseModal.svg';

const SecHeaderNav: React.FC = () => {
  const appContext = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);

  if (!appContext) {
    return null; // or a fallback UI
  }
  const { navMenuTitles } = appContext;

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      align="center"
      bg={theme.colors.white}
      py="32px"
      px="calc((100vw - 1225px) / 2)"
      flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on mobile
    >
      <Flex align="center" mb={{ base: '20px', md: '0' }}>
        <Link to="/">
          <Logo height="22px" />
        </Link>
        <Box
          display={{ base: 'none', md: 'flex' }}
          textAlign="center"
          gap="32px"
          marginRight="56px"
        >
          {navMenuTitles.map((title, index) => (
            <NavMenu
              key={index}
              title={title}
              TextColor={theme.colors.texts.semisecondary}
            />
          ))}
        </Box>
      </Flex>
      <Flex
        display={{ base: 'flex', md: 'none' }}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          ref={btnRef}
          icon={<HamburgerIcon />}
          variant="outline"
          aria-label="Open Menu"
          onClick={onOpen}
        />
        <AuthButtonGroup />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Flex justifyContent="space-between">
              <DrawerHeader
                fontFamily={theme.fonts.family.body}
                color={theme.colors.texts.secondary}
                p="30px"
              >
                منو
              </DrawerHeader>
              <Flex p="30px">
                <CloseModal onClick={onClose} cursor="pointer" />
              </Flex>
            </Flex>
            <DrawerBody>
              {navMenuTitles.map((title, index) => (
                <NavMenu
                  key={index}
                  title={title}
                  TextColor={theme.colors.texts.black}
                />
              ))}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
      <Box display={{ base: 'none', md: 'block' }}>
        <AuthButtonGroup />
      </Box>
    </Flex>
  );
};

export default SecHeaderNav;
