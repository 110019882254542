// UserDropDown.tsx
import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  Text,
  Image,
  Button,
  VStack,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { AuthContext } from '../../context/AuthContext';
import theme from '../../theme';
import { ReactComponent as LogedInArrowDown } from '../../assets/icons/LogedInArrowDown.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/UserIcon.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/ReportIcon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/LogoutIcon.svg';
import { ReactComponent as ReloadIcon } from '../../assets/icons/Retry.svg';

import { logout as authLogout } from '../../services/authService';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const UserDropDown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const Navigate = useNavigate();

  if (!authContext) {
    return null;
  }

  const { user, logout } = authContext;

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 100); // Adjust delay as needed
  };

  const handleLogout = () => {
    authLogout();
    logout();
  };
  const handleNavToLastRep = () => {
    Navigate('/lastreports');
  };
  const handleNavToHome = () => {
    Navigate('/');
  };

  return (
    <Box
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        width="167px"
        height="48px"
        padding="12px 24px"
        gap="8px"
        borderRadius="8px"
        backgroundColor="#4A4A4A"
        color="white"
        _hover={{ backgroundColor: '#333' }}
      >
        <Text
          fontFamily={theme.fonts.family.body}
          fontWeight="500"
          fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
          color={theme.colors.texts.white}
          lineHeight="24px"
        >
          حساب کاربری
        </Text>
        <LogedInArrowDown />
      </Button>
      {isOpen && (
        <MotionBox
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          position="absolute"
          top="60px" // Adjust this to provide space between the button and the box
          backgroundColor="white"
          boxShadow="md"
          borderRadius="8px"
          left={['0px', 'auto']}
          zIndex="dropdown"
          padding="16px"
          w="312px"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <VStack align="start">
            <Flex p="16px" align="center" justifyContent="center">
              <Box ml="8px">
                <UserIcon height="24px" width="24px" />
              </Box>
              <Box textAlign="right">
                <Text
                  mt="6px"
                  fontFamily={theme.fonts.family.medium}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                  color={theme.colors.texts.secondary}
                >
                  {user}
                </Text>
              </Box>
            </Flex>
            <Divider />
            <Button
              p="16px"
              variant="ghost"
              w="full"
              height="auto"
              textAlign="right"
              justifyContent="start"
              onClick={handleNavToLastRep}
            >
              <Box ml="8px">
                <ReportIcon height="24px" width="24px" />
              </Box>
              <Box textAlign="right">
                <Text
                  fontFamily={theme.fonts.family.medium}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                  color={theme.colors.texts.secondary}
                >
                  لیست جستجوهای اخیر
                </Text>
              </Box>
            </Button>
            <Divider />
            <Button
              p="16px"
              variant="ghost"
              w="full"
              height="auto"
              textAlign="right"
              justifyContent="start"
              onClick={handleNavToHome}
            >
              <Box ml="8px">
                <ReloadIcon height="22px" width="24px" />
              </Box>
              <Box textAlign="right">
                <Text
                  fontFamily={theme.fonts.family.medium}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                  color={theme.colors.texts.secondary}
                >
                  جستجوی مجدد
                </Text>
              </Box>
            </Button>
            <Divider />
            <Button
              p="16px"
              onClick={handleLogout}
              variant="ghost"
              w="full"
              height="auto"
              textAlign="right"
              justifyContent="start"
            >
              <Box ml="8px">
                <LogoutIcon height="24px" width="24px" />
              </Box>
              <Box textAlign="right">
                <Text
                  fontFamily={theme.fonts.family.medium}
                  fontWeight="500"
                  fontSize={`clamp(${theme.fonts.size.lil}, 1.5vw, ${theme.fonts.size.small})`}
                  color={theme.colors.texts.secondary}
                >
                  خروج
                </Text>
              </Box>
            </Button>
          </VStack>
        </MotionBox>
      )}
    </Box>
  );
};

export default UserDropDown;
