import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from './constants';
import { logout as logoutService } from './authService';

const REPORT_URL = `${API_BASE_URL}/report`;

const handleUnauthorizedError = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    logoutService();
    window.location.reload(); // Optional: Refresh the page to ensure the user is logged out
  }
  throw error;
};

export const getReportBalance = async (): Promise<any> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/report-balance/`);
    return response.data;
  } catch (error) {
    handleUnauthorizedError(error as AxiosError);
  }
};

export const generateReport = async (
  cv: File,
  filter: object
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('cv', cv);
    formData.append('filter', JSON.stringify(filter));

    const response = await axios.post(`${REPORT_URL}/generate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    handleUnauthorizedError(error as AxiosError);
  }
};

export const getAllReports = async (
  page: number,
  size: number
): Promise<any> => {
  try {
    const response = await axios.get(REPORT_URL, {
      params: {
        page,
        size,
      },
    });
    return response.data;
  } catch (error) {
    handleUnauthorizedError(error as AxiosError);
  }
};

export const getReportWithId = async (reportId: string): Promise<any> => {
  try {
    const response = await axios.get(`${REPORT_URL}/${reportId}`);
    return response.data.body;
  } catch (error) {
    handleUnauthorizedError(error as AxiosError);
  }
};

export const sendFeedback = async (
  profId: number,
  feedback: 'LIKED' | 'DISLIKED'
): Promise<any> => {
  try {
    const body = {
      profId,
      feedBack: feedback,
    };

    const response = await axios.post(`${API_BASE_URL}/report/feedback`, body);
    return response.data;
  } catch (error) {
    handleUnauthorizedError(error as AxiosError);
  }
};
