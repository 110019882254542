import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from 'src/containers/header/Header';
import SecHeaderNav from 'src/containers/header/SecHeaderNav';
import Footer from 'src/containers/Footer';
import Home from 'src/pages/Home';
import Report from 'src/pages/Report';
import LastReports from 'src/pages/LastReports';
import Faq from 'src/pages/faq';
import GoogleAuthCallback from 'src/pages/GoogleAuthCallback';
import AboutUs from 'src/pages/AboutUs';
import ContactUs from 'src/pages/ContactUs';
import LoginError from 'src/pages/LoginError';
import PaymentResult from 'src/pages/PaymentResult';
import MoreDetails from 'src/pages/MoreDetails';
import { PrivateRoute } from 'src/pages/PrivateRoutes';

const DesktopLayout: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header />
            <Home />
            <Footer />
          </>
        }
      />
      <Route
        path="/plans"
        element={
          <>
            <Header />
            <Home />
            <Footer />
          </>
        }
      />
      <Route
        path="/login"
        element={
          <>
            <Header />
            <Home />
            <Footer />
          </>
        }
      />
      <Route
        path="/report/:reportId"
        element={
          <PrivateRoute>
            <>
              <SecHeaderNav />
              <Report />
              <Footer />
            </>
          </PrivateRoute>
        }
      />
      <Route
        path="/lastreports"
        element={
          <>
            <SecHeaderNav />
            <LastReports />
            <Footer />
          </>
        }
      />
      <Route
        path="/faq"
        element={
          <>
            <SecHeaderNav />
            <Faq />
            <Footer />
          </>
        }
      />
      <Route path="/login/google" element={<GoogleAuthCallback />} />
      <Route
        path="/login/error"
        element={
          <>
            <SecHeaderNav />
            <LoginError />
            <Footer />
          </>
        }
      />
      <Route
        path="/payment-result"
        element={
          <>
            <SecHeaderNav />
            <PaymentResult />
            <Footer />
          </>
        }
      />
      <Route
        path="/aboutus"
        element={
          <>
            <SecHeaderNav />
            <AboutUs />
            <Footer />
          </>
        }
      />
      <Route
        path="/moredetails"
        element={
          <>
            <SecHeaderNav />
            <MoreDetails />
            <Footer />
          </>
        }
      />
      <Route
        path="/contactus"
        element={
          <>
            <SecHeaderNav />
            <ContactUs />
            <Footer />
          </>
        }
      />
    </Routes>
  );
};

export default DesktopLayout;
