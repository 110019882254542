import React, { useState, useContext } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  Divider,
  Image,
  useBreakpointValue,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import moment from 'jalali-moment';
import theme from 'src/theme';
import { AuthContext } from 'src/context/AuthContext';
import { validateVoucher } from 'src/services/voucherService';
import { handlePayment } from 'src/components/plans/handlePayment';
import { useLocation } from 'react-router-dom';
import PagesHeader from 'src/components/mobile/PagesHeader';

interface Plan {
  id: number;
  price: number;
  reportPerPayment: number;
  resultLimit: number;
}
interface selectedPlan {
  planID: string;
  title: string;
  duration: string;
  price: string;
}

const Payment: React.FC = () => {
  const location = useLocation();
  const selectedPlan: Plan = location.state?.selectedPlan;
  const onBack = location.state?.onBack;
  const [voucherCode, setVoucherCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [voucherStatus, setVoucherStatus] = useState<string | null>(null);
  const currentDate = moment().locale('fa').format('YYYY-MM-DD, HH:mm:ss');
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }

  const { user } = authContext;
  const userIdentifier = user ? user : '';

  const handleVoucherInput = async () => {
    const parsePriceString = (priceString: string): number => {
      const cleanedPriceString = priceString
        .replace(/[^\d۰-۹]/g, '')
        .replace(/[۰٠]/g, '0')
        .replace(/[۱١]/g, '1')
        .replace(/[۲٢]/g, '2')
        .replace(/[۳٣]/g, '3')
        .replace(/[۴٤]/g, '4')
        .replace(/[۵٥]/g, '5')
        .replace(/[۶٦]/g, '6')
        .replace(/[۷٧]/g, '7')
        .replace(/[۸٨]/g, '8')
        .replace(/[۹٩]/g, '9');

      const priceWithoutCommas = cleanedPriceString.replace(/,/g, '');
      return parseFloat(priceWithoutCommas);
    };

    if (!selectedPlan) return;

    try {
      console.log('selectedPlan.price in Mobile');
      console.log(selectedPlan.price);

      const price = parsePriceString(
        selectedPlan.price.toLocaleString('fa-IR')
      );

      const discountAmount = await validateVoucher(
        voucherCode,
        userIdentifier,
        price
      );
      const discountPercent = (discountAmount / price) * 100;
      setDiscountPercentage(discountPercent);
      setVoucherStatus('success');
    } catch (error) {
      setDiscountPercentage(0);
      setVoucherStatus('error');
    }
  };

  const onPaymentClick = () => {
    console.log(selectedPlan);
    console.log(discountPercentage);

    handlePayment(
      selectedPlan,
      discountPercentage > 0 ? voucherCode : undefined,
      discountPercentage
    );
  };

  return (
    selectedPlan && (
      <Box minH="100vh" backgroundColor="#ffff">
        <PagesHeader text={`فاکتور پرداخت`} showBackButton={true} />

        <Box
          px="16px"
          pt="40px"
          height="calc(100vh - 80px)"
          overflowY="auto"
          bg={theme.colors.pbg}
        >
          <Box
            backgroundColor={theme.colors.mobile.secondary}
            fontFamily={theme.fonts.family.body}
            fontWeight="500"
            fontSize={theme.fonts.size.small}
            color={theme.colors.secondary}
            paddingX="16px"
            borderRadius="8px"
            textAlign="start"
            padding="12px 16px"
            marginTop="24px"
            marginBottom="16px"
          >
            <Text fontWeight="700" marginBottom="16px">
              مشخصات تراکنش
            </Text>

            <Flex justify="space-between" marginBottom="14px">
              <Text>مبلغ:</Text>
              <Text>{selectedPlan.price.toLocaleString()} ریال</Text>
            </Flex>
            <Flex justify="space-between" marginBottom="14px">
              <Text>تاریخ:</Text>
              <Text>{currentDate}</Text>
            </Flex>
            <Flex justify="space-between" marginBottom="14px">
              <Text>نوع اشتراک:</Text>
              <Text>
                {selectedPlan.price === 0 ? 'اشتراک رایگان' : 'اشتراک بتا'}
              </Text>
            </Flex>
          </Box>

          <InputGroup>
            <Input
              placeholder="کد تخفیف خود را وارد کنید"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              sx={{
                paddingRight: '20px',
                paddingLeft: '100px',
                height: '48px',
                borderRadius: '8px',
                border: '1px solid #D2D5DA',
                background: '#FFFFFF',
                paddingTop: '5px',
              }}
            />
            <InputLeftElement width="auto" marginTop="4px" marginLeft="8px">
              <Button
                size="sm"
                onClick={handleVoucherInput}
                sx={{
                  margin: '0px',
                  height: '32px',
                  borderRadius: '8px',
                  background: '#3080E2',
                  color: '#fff',
                }}
              >
                اعمال کد تخفیف
              </Button>
            </InputLeftElement>
          </InputGroup>
          {voucherStatus && (
            <Text color={voucherStatus === 'success' ? 'green' : 'red'} mb={2}>
              {voucherStatus === 'success'
                ? 'کد تخفیف اعمال شد.'
                : 'کد تخفیف نادرست است.'}
            </Text>
          )}
        </Box>
        <Flex
          justify="space-between"
          position="fixed"
          bottom="0"
          left="0"
          width="100%"
          backgroundColor="#FFFFFF"
          padding="16px"
          height="80px"
          boxShadow="0px 1px 0px 0px #E6E6E6 inset"
        >
          <Box width="50%">
            <Text>مبلغ قابل پرداخت:</Text>
            <Text as="span" paddingLeft="4px" marginTop="2px">
              {(
                selectedPlan.price -
                (selectedPlan.price * discountPercentage) / 100
              ).toLocaleString()}
            </Text>
            <Text as="span">ریال</Text>
          </Box>
          <Button
            width="50%"
            backgroundColor={theme.colors.secondary}
            borderRadius="8px"
            h="48px"
            color={theme.colors.texts.white}
            onClick={onPaymentClick}
          >
            پرداخت
          </Button>
        </Flex>
      </Box>
    )
  );
};

export default Payment;
