import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Link,
  Box,
  HStack,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { Spinner } from '@chakra-ui/react';

import { ReactComponent as CloseModalIcon } from 'src/assets/mobile/icons/CloseModal.svg';
import { ReactComponent as LikeIcon } from 'src/assets/mobile/icons/like.svg';
import { ReactComponent as LikedIcon } from 'src/assets/mobile/icons/liked.svg';
import { ReactComponent as DislikeIcon } from 'src/assets/mobile/icons/dislike.svg';
import { ReactComponent as DislikedIcon } from 'src/assets/mobile/icons/disliked.svg';
import { sendFeedback } from 'src/services/reportService';

interface Professor {
  id: number;
  author: string;
  scholarId: string | null;
  urlPicture: string;
  affiliation: string;
  email: string | null;
  interests: string[];
  authorCitations: number;
  university: string | null;
  country: string;
  feedBack: string;
  universityWorldRank: number;
  universityCountryRank: number;
  interestSimilarityScore: number;
}

interface ReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  professor: Professor | null;
}

const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  onClose,
  professor,
}) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedFeedback = localStorage.getItem(`feedback_${professor?.id}`);
    if (professor) {
      setLiked(storedFeedback === 'LIKED');
      setDisliked(storedFeedback === 'DISLIKED');
    }
  }, [professor]);

  const updateFeedback = async (feedbackType) => {
    setIsLoading(true);
    if (professor) {
      try {
        await sendFeedback(professor.id, feedbackType);
        localStorage.setItem(`feedback_${professor.id}`, feedbackType);
        setLiked(feedbackType === 'LIKED');
        setDisliked(feedbackType === 'DISLIKED');
      } catch (error) {
        console.error(`Failed to send ${feedbackType}:`, error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLike = () => {
    if (professor && !liked) updateFeedback('LIKED');
  };

  const handleDislike = () => {
    if (professor && !disliked) updateFeedback('DISLIKED');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        borderTopRadius="lg"
        pb={4}
        position="relative"
        width="100%"
        minWidth="99%"
        borderRadius="8px 8px 0px 0px"
      >
        <Box position="absolute" top="24px" left="24px" onClick={onClose}>
          <CloseModalIcon />
        </Box>
        <ModalHeader
          textAlign="start"
          height="72px"
          minHeight="72px"
          borderBottom="solid 1px #EBEBF0"
        >
          جزئیات
        </ModalHeader>
        <ModalBody>
          {professor && (
            <VStack
              align="flex-start"
              width="100%"
              marginTop="8px"
              marginBottom="16px"
            >
              <Flex
                width="100%"
                padding="8px 16px"
                borderBottom="solid 1px #EBEBF0"
                justifyContent="space-between"
              >
                <Text textAlign="start" fontWeight="bold">
                  نام استاد
                </Text>
                <Text textAlign="start">{professor.author}</Text>
              </Flex>
              <Flex
                width="100%"
                padding="8px 16px"
                borderBottom="solid 1px #EBEBF0"
                justifyContent="space-between"
              >
                <Text textAlign="start" fontWeight="bold" minWidth="30%">
                  نام دانشگاه
                </Text>
                <Text textAlign="end">{professor.affiliation}</Text>
              </Flex>
              <Flex
                width="100%"
                padding="8px 16px"
                borderBottom="solid 1px #EBEBF0"
                justifyContent="space-between"
              >
                <Text textAlign="start" fontWeight="bold">
                  رتبه کشوری دانشگاه (QS)
                </Text>
                <Text textAlign="end">{professor.universityCountryRank}</Text>
              </Flex>
              <Flex
                width="100%"
                padding="8px 16px"
                borderBottom="solid 1px #EBEBF0"
                justifyContent="space-between"
              >
                <Text textAlign="start" fontWeight="bold">
                  رتبه جهانی دانشگاه
                </Text>
                <Text textAlign="end">{professor.universityWorldRank}</Text>
              </Flex>
              <Flex
                width="100%"
                padding="8px 16px"
                justifyContent="space-between"
              >
                <Text textAlign="start" fontWeight="bold">
                  لینک گوگل استاد
                </Text>
                <Link
                  onClick={() => {
                    const term = professor.author || 'N/A';
                    window.open(
                      `https://google.com/search?q=professor ${encodeURIComponent(
                        term
                      )}`,
                      '_blank'
                    );
                  }}
                  isExternal
                  color="blue.500"
                  textAlign="end"
                >
                  لینک
                </Link>
              </Flex>
            </VStack>
          )}
        </ModalBody>
        {isLoading && (
          <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            justifyContent="center"
            alignItems="center"
            backgroundColor="rgba(255, 255, 255, 0.8)" // Semi-transparent background
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}
        <HStack justify="center" padding="16px" borderTop="solid 1px #EBEBF0">
          <Flex
            width="100%"
            border="solid 1px #404040"
            borderRadius="8px"
            justifyContent="center"
            paddingY="12px"
            cursor="pointer"
            onClick={handleDislike}
          >
            <Box
              transition="all 0.3s ease"
              _hover={{ transform: 'scale(1.05)' }}
            >
              {disliked ? <DislikedIcon /> : <DislikeIcon />}
            </Box>
          </Flex>
          <Flex
            width="100%"
            border="solid 1px #404040"
            borderRadius="8px"
            justifyContent="center"
            paddingY="12px"
            cursor="pointer"
            onClick={handleLike}
          >
            <Box
              transition="all 0.3s ease"
              _hover={{ transform: 'scale(1.05)' }}
            >
              {liked ? <LikedIcon /> : <LikeIcon />}
            </Box>
          </Flex>
        </HStack>
      </ModalContent>
    </Modal>
  );
};

export default ReportModal;
