import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { ReactComponent as ReportNavIcon } from 'src/assets/mobile/icons/ReportNavIcon.svg';
import { ReactComponent as ReportNavActiveIcon } from 'src/assets/mobile/icons/ReportNavActiveIcon.svg';
import { ReactComponent as AddNavIcon } from 'src/assets/mobile/icons/AddNavIcon.svg';
import { ReactComponent as AddNavActiveIcon } from 'src/assets/mobile/icons/AddNavIconActive.svg';
import { ReactComponent as ProfileNavIcon } from 'src/assets/mobile/icons/ProfileNavIcon.svg';
import { ReactComponent as ProfileNavActiveIcon } from 'src/assets/mobile/icons/ProfileNavActiveIcon.svg';
import theme from 'src/theme';

const BottomNavigation: React.FC = () => {
  return (
    <Box
      position="fixed"
      bottom="0px"
      width="100%"
      bg="#fff"
      height="60px"
      boxShadow="0 -1px 5px rgba(0,0,0,0.1)"
      zIndex="1000"
    >
      <Flex justifyContent="space-around" padding="8px" marginBottom="6px">
        <NavLink to="/">
          {({ isActive }) => (
            <Flex direction="column" align="center">
              {isActive ? <ReportNavActiveIcon /> : <ReportNavIcon />}
              <Box
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={theme.fonts.size.lil}
                color={
                  isActive
                    ? theme.colors.mobile.mobileblue
                    : theme.colors.texts.secondary
                }
                mt="4px"
              >
                گزارش‌ها
              </Box>
            </Flex>
          )}
        </NavLink>
        <NavLink to="/new-request">
          {({ isActive }) => (
            <Flex direction="column" align="center">
              {isActive ? <AddNavActiveIcon /> : <AddNavIcon />}
              <Box
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={theme.fonts.size.lil}
                color={
                  isActive
                    ? theme.colors.mobile.mobileblue
                    : theme.colors.texts.secondary
                }
                mt="4px"
              >
                درخواست جدید
              </Box>
            </Flex>
          )}
        </NavLink>
        <NavLink to="/profile">
          {({ isActive }) => (
            <Flex direction="column" align="center">
              {isActive ? <ProfileNavActiveIcon /> : <ProfileNavIcon />}
              <Box
                fontFamily={theme.fonts.family.body}
                fontWeight="400"
                fontSize={theme.fonts.size.lil}
                color={
                  isActive
                    ? theme.colors.mobile.mobileblue
                    : theme.colors.texts.secondary
                }
                mt="4px"
              >
                پروفایل
              </Box>
            </Flex>
          )}
        </NavLink>
      </Flex>
    </Box>
  );
};

export default BottomNavigation;
