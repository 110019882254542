import React, { useContext, useEffect, useState } from 'react';
import { Box, VStack, Text, Flex, useToast } from '@chakra-ui/react';

import theme from 'src/theme';
import { AuthContext } from 'src/context/AuthContext';
import axios, { AxiosError } from 'axios';
import Loader from 'src/components/Loader';
import { handleUnauthorizedError } from 'src/services/authService';
import { useNavigate } from 'react-router-dom';
import PagesHeader from 'src/components/mobile/PagesHeader';
import { getReportBalance } from 'src/services/reportService';

interface Plan {
  id: number;
  price: number;
  reportPerPayment: number;
  resultLimit: number;
}
interface Plans {
  id: number;
  userEmail: string;
  price: number;
  reportPerPayment: number;
  resultLimit: number;
  reportType: Plan;
  reportBalance: number;
}

const UserPlans: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [Plans, setplans] = useState<Plans[] | null>(null);
  const [pricedPlan, setPricedPlan] = useState<Plan | null>(null);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(AuthContext) || {};

  const handleUserPlanselect = (plan: Plan) => {
    if (!isAuthenticated) {
      setAuthModalOpen(true);
      return;
    }
    if (selectedPlan && selectedPlan.id === plan.id) {
      setSelectedPlan(null);
    } else {
      setSelectedPlan(plan);
    }
  };

  const fetchPlanData = async () => {
    try {
      const data = await getReportBalance();
      if (data.body && data.body.length > 0) {
        setplans(data.body);
      } else {
        navigate('/plans');
      }
    } catch (error) {
      console.error('Error fetching plan data:', error);
      handleUnauthorizedError(error as AxiosError);
      toast({
        title: 'Plan data error',
        description: (error as AxiosError).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanData();
  }, []);

  return (
    <Box
      backgroundColor={theme.colors.mobile.pbg}
      minHeight="100vh"
      paddingTop="80px"
      paddingBottom="80px"
    >
      <Box backgroundColor="#fff">
        <PagesHeader text={`اشتراک های فعال`} showBackButton={true} />
      </Box>

      <VStack paddingX="16px" spacing="16px">
        {loading ? (
          <Loader color1="#56A2FF" color2="#FFF" size={200} />
        ) : (
          <>
            {Plans?.map((plan) => (
              <Box
                key={plan.id}
                padding="16px"
                borderWidth={1}
                borderColor={
                  selectedPlan && selectedPlan.id === plan.id
                    ? '#3080E2'
                    : '#EBEBF0'
                }
                borderRadius="16px"
                width="100%"
                textAlign="center"
                backgroundColor="#fff"
                onClick={() => handleUserPlanselect(plan)}
                cursor="pointer"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="start"
                  marginBottom="8px"
                >
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.semismall}
                    fontWeight="600"
                  >
                    {plan.reportType.price === 0
                      ? 'اشتراک رایگان'
                      : 'اشتراک بتا'}
                  </Text>
                  {plan.reportType.price != 0 && (
                    <Box bg="#3080E2" borderRadius="20px" color="#fff">
                      <Text
                        fontFamily={theme.fonts.family.body}
                        fontSize={theme.fonts.size.vlil}
                        fontWeight="500"
                        padding="5px 12px"
                      >
                        پیشنهاد ما
                      </Text>
                    </Box>
                  )}
                </Flex>
                <Flex justifyContent="space-between" alignItems="start">
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="400"
                  >
                    تعداد گزارش باقی مانده:
                  </Text>
                  <Text
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    fontWeight="500"
                  >
                    {`${plan.reportBalance} گزارش`}
                  </Text>
                </Flex>
              </Box>
            ))}
          </>
        )}
      </VStack>
    </Box>
  );
};

export default UserPlans;
