import React, { useState, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  useToast,
  Spinner,
  Center,
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { ReactComponent as Logo } from '../../assets/images/SecLogo.svg';
import theme from '../../theme';
import { AuthContext } from '../../context/AuthContext';
import AuthStepOne from './AuthStepOne';
import AuthStepTwo from './AuthStepTwo';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isLoading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);
  const toast = useToast({
    position: 'top',
    containerStyle: {
      textAlign: 'center',
      width: '500px',
      maxWidth: '100%',
    },
  });

  if (!authContext) {
    return null;
  }

  const { sendOtp, verifyOtp } = authContext;

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = async () => {
    if (!email) {
      toast({
        description: 'لطفا ایمیل خود را وارد کنید.',
        status: 'error',
        duration: 3000,
        variant: 'left-accent',
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
      });
      return;
    }

    if (!isValidEmail(email)) {
      toast({
        description: 'لطفا ایمیل خود را به درستی وارد کنید.',
        status: 'error',
        duration: 3000,
        variant: 'left-accent',
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
      });
      return;
    }

    try {
      setLoading(true);
      await sendOtp(email);
      setStep(2);
    } catch (error) {
      console.error('Failed to send OTP:', error);

      // Check if error is an instance of AxiosError
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 429) {
          toast({
            description:
              'درخواست با این ایمیل زیاد بود. لطفا کمی بعد دوباره تلاش کنید.',
            status: 'error',
            duration: 3000,
            variant: 'left-accent',
            containerStyle: {
              textAlign: 'center',
              fontFamily: `${theme.fonts.family.body}`,
              fontWeight: 'medium',
            },
          });
        } else {
          toast({
            description: 'خطایی در ارسال کد پیش آمد. لطفا دوباره تلاش کنید.',
            status: 'error',
            duration: 3000,
            variant: 'left-accent',
            containerStyle: {
              textAlign: 'center',
              fontFamily: `${theme.fonts.family.body}`,
              fontWeight: 'medium',
            },
          });
        }
      } else {
        toast({
          description: 'خطایی در ارسال کد پیش آمد. لطفا دوباره تلاش کنید.',
          status: 'error',
          duration: 3000,
          variant: 'left-accent',
          containerStyle: {
            textAlign: 'center',
            fontFamily: `${theme.fonts.family.body}`,
            fontWeight: 'medium',
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async () => {
    try {
      setLoading(true);
      await verifyOtp(email, code);
      onClose();
    } catch (error) {
      console.error('Failed to verify OTP:', error);
      toast({
        description: 'لطفا کد ارسال شده را دوباره بررسی کنید.',
        status: 'error',
        duration: 3000,
        variant: 'left-accent',
        containerStyle: {
          textAlign: 'center',
          fontFamily: `${theme.fonts.family.body}`,
          fontWeight: 'medium',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTimerEnd = () => {
    setStep(1);
    setCode('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent
        width="500px"
        maxH="400px"
        maxW="500px"
        borderRadius="24px"
        p="40px 48px"
        boxShadow="5px 4px 50px 5px rgba(51, 84, 244, 0.3)"
      >
        {isLoading && (
          <ModalOverlay bg="blackAlpha.600">
            <Center h="100%">
              <Spinner size="xl" color="white" />
            </Center>
          </ModalOverlay>
        )}
        <ModalHeader p="0px">
          <Flex justifyContent="flex-end" mb="30px">
            <Logo />
          </Flex>
        </ModalHeader>
        <ModalBody textAlign="right" p="0px">
          {step === 1 && (
            <AuthStepOne
              email={email}
              setEmail={setEmail}
              handleEmailSubmit={handleEmailSubmit}
            />
          )}
          {step === 2 && (
            <AuthStepTwo
              code={code}
              setCode={setCode}
              handleCodeSubmit={handleCodeSubmit}
              onTimerEnd={handleTimerEnd} // Pass the callback to AuthStepTwo
            />
          )}
          {/* {isLoading && <Spinner />} */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;
