import React, { useState, useEffect, useRef } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  Button,
  Box,
  Input,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider,
  Flex,
  Text,
  InputGroup,
  InputLeftElement,
  Spinner,
  InputRightElement,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { fetchInterestsAPI } from '../../services/FettchData';
import theme from '../../theme';
import ArrowDownChevron from '../../assets/icons/ArrowDown.png';
import CustomCheckbox from '../CustomCheckboxProps';
import { ReactComponent as CloseModal } from '../../assets/icons/CloseModal.svg';
import { ReactComponent as ClearTextIcon } from 'src/assets/icons/ClearTextIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';

interface Option {
  value: string;
}

interface DropdownIntrestsCheckboxesProps {
  placeholder: string;
  onConfirm: (selectedValues: string[]) => void;
}

const DropdownIntrestsCheckboxes: React.FC<DropdownIntrestsCheckboxesProps> = ({
  placeholder,
  onConfirm,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const fetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentFetchRequestRef = useRef<AbortController | null>(null);
  const [showNoResults, setShowNoResults] = useState<boolean>(false);

  const fetchInterests = async (query: string) => {
    if (currentFetchRequestRef.current) {
      currentFetchRequestRef.current.abort();
    }
    const controller = new AbortController();
    currentFetchRequestRef.current = controller;
    setIsLoading(true);
    try {
      const response = await fetchInterestsAPI(query, controller.signal);
      const uniqueInterests = Array.from(new Set(response as string[]));
      return uniqueInterests.map((interest: string) => ({
        value: interest,
      }));
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching interests:', error);
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      if (searchQuery) {
        fetchTimeoutRef.current = setTimeout(() => {
          fetchInterests(searchQuery)
            .then((data: Option[]) => {
              setFilteredOptions(data);
              setShowNoResults(data.length === 0); // Show "No results found" if no data after fetching
            })
            .catch((error) => console.error('Error loading options:', error));
        }, 500);
      } else {
        setFilteredOptions([
          {
            value: 'Artificial Intelligence',
          },
          {
            value: 'Quantum Computing',
          },
          {
            value: 'Sustainable Energy',
          },
          {
            value: 'Gene Editing',
          },
          {
            value: 'Climate Change',
          },
          {
            value: 'Cybersecurity',
          },
        ]);
      }
    }
  }, [isMenuOpen, searchQuery]);

  useEffect(() => {
    // Timer to show "No results found" after 10 seconds of inactivity
    let timer: NodeJS.Timeout;
    if (searchQuery.length === 0) {
      timer = setTimeout(() => {
        setShowNoResults(true);
      }, 10000);
    } else {
      setShowNoResults(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchInputRef.current && !isFocused) {
      searchInputRef.current.focus();
    }
  }, [isFocused]);

  const handleCheckboxChange = (value: string) => {
    if (selectedValues.includes(value)) {
      setSelectedValues((prev) => prev.filter((v) => v !== value));
    } else {
      if (selectedValues.length >= 10) {
        setSelectedValues((prev) => {
          const updatedValues = [...prev];
          updatedValues.pop();
          updatedValues.push(value);
          return updatedValues;
        });
      } else {
        setSelectedValues((prev) => [...prev, value]);
      }
    }
  };

  const handleConfirm = () => {
    onConfirm(selectedValues);
    setIsMenuOpen(false);
    onClose();
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    onOpen();
  };

  const handleModalClose = () => {
    setSelectedValues([]);
    setIsMenuOpen(false);
    onClose();
  };
  const clearInput = () => {
    setSearchQuery('');
    searchInputRef.current?.focus();
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    onClose();
  };

  const combinedOptions = [
    ...selectedValues
      .map((value) => options.find((option) => option?.value === value))
      .filter((option): option is Option => option !== undefined),
    ...filteredOptions.filter(
      (option) => !selectedValues.includes(option?.value)
    ),
  ];

  return (
    <Box marginBottom="20px">
      <Menu isOpen={isMenuOpen} onOpen={handleMenuOpen}>
        <MenuButton
          as={Button}
          leftIcon={<Image src={ArrowDownChevron} width="24px" height="24px" />}
          fontFamily={theme.fonts.family.body}
          fontSize={theme.fonts.size.small}
          color={theme.colors.texts.secondary}
          fontWeight="regular"
          width="100%"
          border="1px solid #ECECEC"
          borderRadius="0px"
          backgroundColor="white"
          textAlign="left"
          padding="16px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          className="dropdown_button"
          maxWidth="400px"
        >
          <Box
            as="span"
            display="block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            className="ltr-direction"
          >
            {selectedValues.length !== 0
              ? selectedValues.map((value, index) => (
                  <React.Fragment key={index}>
                    <Text
                      color="grey"
                      as="span"
                      fontSize={theme.fonts.size.small}
                      m="6px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      lineHeight="25px"
                      className="ltr-direction"
                    >
                      {index !== 0 && ' '}#{value}
                    </Text>
                  </React.Fragment>
                ))
              : placeholder}
          </Box>
        </MenuButton>
        <Modal
          isOpen={isOpen}
          onClose={handleModalClose}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}
          autoFocus={false}
        >
          <ModalOverlay />
          <ModalContent w={['95%', '481px']} minHeight={['570px', '570px']}>
            <ModalHeader p="16px 24px">
              <Flex justifyContent="space-between">
                <Text fontFamily={theme.fonts.family.body}>
                  {placeholder + ' '}
                  <Text
                    as="span"
                    fontFamily={theme.fonts.family.body}
                    fontSize={theme.fonts.size.small}
                    color={theme.colors.texts.secondary}
                  >
                    (حداکثر 10 مورد به انگلیسی وارد شود)
                  </Text>
                </Text>
                <CloseModal onClick={handleModalClose} cursor="pointer" />
              </Flex>
            </ModalHeader>
            <ModalBody pt="16px">
              <InputGroup>
                {!isFocused && (
                  <InputLeftElement pointerEvents="none">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box mt="7px" ml="20px">
                        <SearchIcon width="16px" height="16px" />
                      </Box>
                    </motion.div>
                  </InputLeftElement>
                )}
                <Input
                  // ref={searchInputRef}
                  lineHeight="normal"
                  dir="ltr"
                  placeholder={
                    !isFocused ? 'Search' : `Search in ${placeholder}`
                  }
                  _placeholder={{
                    alignContent: 'center',
                    color: !isFocused
                      ? theme.colors.texts.secondary
                      : '#8C8C8C',
                    fontFamily: theme.fonts.family.body,
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: 'normal',
                    transition: 'color 0.2s ease',
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      setIsFocused(false);
                    }
                  }}
                  _focus={{ border: '1px solid #D9D9D9', shadow: 'none' }}
                  p="12px 20px"
                  pl={!isFocused ? '44px' : '16px'}
                  transition="padding-left 0.2s ease"
                  marginBottom="10px"
                  backgroundColor="#F8F9FB"
                  border="none"
                  borderRadius="6px"
                  h="48px"
                  textAlign="left"
                  autoFocus={false}
                />
                {searchQuery && (
                  <InputRightElement padding="0px">
                    <Button
                      marginTop="8px"
                      onClick={clearInput}
                      padding="0px"
                      backgroundColor="transparent"
                      _hover={{
                        backgroundColor: 'transparent',
                        transform: 'scale(1.1)',
                      }}
                    >
                      <ClearTextIcon />
                    </Button>
                  </InputRightElement>
                )}
              </InputGroup>
              <Box maxHeight="358px" overflowY={isLoading ? 'hidden' : 'auto'}>
                {selectedValues.map((value) => (
                  <React.Fragment key={value}>
                    <Box
                      py="0px"
                      px="8px"
                      _hover={{
                        backgroundColor: 'rgba(0, 0, 255,0.02)',
                        borderRadius: '8px',
                      }}
                    >
                      <CustomCheckbox
                        isChecked={selectedValues.includes(value)}
                        label={value}
                        onChange={() => handleCheckboxChange(value)}
                      />
                    </Box>
                    <Divider />
                  </React.Fragment>
                ))}
                {isLoading && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    mt="20px"
                  >
                    <Spinner />
                  </Flex>
                )}
                {!isLoading &&
                  combinedOptions.length === 0 &&
                  showNoResults && (
                    <Box mt="20px" textAlign="center">
                      No results found
                    </Box>
                  )}
                {!isLoading &&
                  combinedOptions
                    .filter((option) => !selectedValues.includes(option.value))
                    .map((option) => (
                      <React.Fragment key={option.value}>
                        <Box
                          py="0px"
                          px="8px"
                          _hover={{
                            backgroundColor: 'rgba(0, 0, 255,0.02)',
                            borderRadius: '8px',
                          }}
                        >
                          <CustomCheckbox
                            isChecked={selectedValues.includes(option.value)}
                            label={option.value}
                            onChange={() => handleCheckboxChange(option.value)}
                          />
                        </Box>
                        <Divider />
                      </React.Fragment>
                    ))}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                width="100%"
                onClick={handleConfirm}
                borderRadius="8px"
                backgroundColor={theme.colors.texts.secondary}
                fontFamily={theme.fonts.family.bold}
                fontSize={`clamp(${theme.fonts.size.small}, 1.5vw, ${theme.fonts.size.semismall})`}
                color={theme.colors.texts.white}
                _hover={{
                  backgroundColor: theme.colors.hover,
                }}
                autoFocus={false}
              >
                تایید
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Menu>
    </Box>
  );
};

export default DropdownIntrestsCheckboxes;
